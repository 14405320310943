import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default {
	getCookie: (name) => {
		return cookies.get(name);
	},
	setCookie: (name, value, expiryDays) => {
		const cookieExpiryDate = new Date(new Date().setDate(new Date().getDate() + expiryDays));
		let cookieDomain = "localhost";
		if (window.location.hostname !== "localhost") {
			let domainHost = window.location.host.split(".");
			domainHost.shift();
			cookieDomain = "." + domainHost.join(".");
		}
		cookies.set(name, value, { expires: cookieExpiryDate, domain: cookieDomain });
	},
	setStrictCookie: (name, value, expiryDays) => {
		const cookieExpiryDate = new Date(new Date().setDate(new Date().getDate() + expiryDays));
		let cookieDomain = "localhost";
		if (window.location.hostname !== "localhost") {
			let domainHost = window.location.host.split(".");
			domainHost.shift();
			cookieDomain = "." + domainHost.join(".");
		}
		cookies.set(name, value, { expires: cookieExpiryDate, domain: cookieDomain, sameSite:"strict" });
	},
	removeCookie: (name) => {
		cookies.remove(name);
	}
}
