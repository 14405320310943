import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import TextInput from '../shared/controls/TextInput';
import { useTranslation } from 'react-i18next';
import { useDetectClickOutside } from 'react-detect-click-outside';
import cookieHelper from '../../helpers/CookieHelper';


const GoogleAddressLookup = (props) => {
	const { t } = useTranslation();
	const [address, setAddress] = React.useState(props.addressValue);
	const [displaySuggestions, setDisplaySuggestions] = React.useState(true);
	const selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");
	const addressLabel = selectedLanguage == "en" ? t('POSTCODE') : t('ADDRESS');

	const handleSelect = async (value, placeID, suggestion) => {
		setAddress(value);
		props.selectedAddress(value);
	};

	const handleOnChange = (value) => {	
		setAddress(value);
		props.selectedAddress(value);
	};

	function handleAddressEvents(value) {
		setDisplaySuggestions(value);		
	}

	return (
		<div>
			<PlacesAutocomplete
				value={address}
				onChange={handleOnChange}
				onSelect={handleSelect}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<div >
						<TextInput {...getInputProps()} placeholder={addressLabel} value={address} triggerValidation={props.triggerValidation}
							showValidationMsg={props.showValidationMsg} name="Address" handleAddressEvents={handleAddressEvents} missingValueMessage={props.missingValueMessage} />

						<div>							

							{suggestions.map(suggestion => {
								const style = {
									backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
								};
								return (
									<> {displaySuggestions ?
										<div {...getSuggestionItemProps(suggestion, { style })}>
											{suggestion.description}
										</div>
										:
										null
									}
									</>
								);
							})}
						</div>
					</div>
				)}
			</PlacesAutocomplete>
		</div>
	);
}

export default GoogleAddressLookup;