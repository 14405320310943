import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsHelper from '../helpers/AnalyticsHelper';


const AlternativeVilla = (props) => {
	document.title = props.tabTitle;
	const { t } = useTranslation();

	useEffect(() => {
		AnalyticsHelper.TrackPageLoad("alternate_villas", "Booking Alternative Accommodation");
	}, [])

	let message = props.history.location.state?.data;

	return (
		<div className="content-container">
			<div className="row w-100">
				<div className="col-12">
					<div className="card grey-colour">
						<div className="card-body">
							{message}
						</div>
					</div>
				</div>
			</div>
		</div>
		)
}

export default AlternativeVilla;