import React from 'react';
import bookSafeIcon from '../../images/lock-icon.svg';
import { useTranslation } from 'react-i18next';

const BookSafe = () => {
	const { t } = useTranslation();

	let content = (
		<div className="book-safe d-flex justify-content-center align-items-center">
			<img alt="" className="bs-lock-icon" src={bookSafeIcon} />
			<div className="bs-text">{t("BOOK_SAFE")}</div>
		</div>
	);

	return content;
}

export default BookSafe;