import axios from 'axios';
import cookieHelper from '../helpers/CookieHelper';

async function GetAvailableExtras() {
	const inventoryResource = localStorage.getItem("InventoryResource");
	const accommodationBookingResource = localStorage.getItem("AccommodationBookingResource");
	const currencyCode = localStorage.getItem("CurrencyCode");
	let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

	if (selectedLanguage == "en-US")
		selectedLanguage = "en";

	return await axios.get("api/AccommodationBooking/GetAvailableExtras?AccommodationBookingResource=" + accommodationBookingResource + "&currencyCode=" + currencyCode + "&language=" + selectedLanguage + "&inventoryResource=" + inventoryResource);
}

async function ClearExtra(extraType, amountToRemove) {
	const accommodationBookingResource = localStorage.getItem("AccommodationBookingResource");

	return await axios.get("api/AccommodationBooking/ClearExtras?accommodationBookingResource=" + accommodationBookingResource + "&extraType=" + extraType + "&amountToRemove=" + amountToRemove);
}

async function AddExtra(resourceLink, count) {
	return await axios.post("api/AccommodationBooking/AddExtra", {
		resourceLink: resourceLink,
		count: count
	})
}

async function GetExtrasInformation(availableExtras) {
	const availableExtraTypes = availableExtras.map((x) => { return x.type });
	let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

	if (selectedLanguage == "en-US")
		selectedLanguage = "en";

	return await axios.post("api/AccommodationBooking/GetExtrasInformation?culture=" + selectedLanguage, availableExtraTypes)
}

export { GetAvailableExtras, AddExtra, GetExtrasInformation, ClearExtra };