import React, { useEffect, useState } from 'react';
import CostDetails from './CostDetails';
import './checkout.css';
import { useTranslation } from 'react-i18next';
import { appInsights } from '../../../services/AppInsights';


const Checkout = (props) => {
	const [GatewayStyle, setGatewayStyle] = useState(null);
	const { t } = useTranslation();

	const {
		checkoutResponse,
		selectedPaymentOption,
		setErrorMessage,
		setSuccessMessage,
		startCheckout,
		paymentOptionClickHandler,
		isSdkLoaded
	} = props;

	useEffect(() => {
		SelectCss();
	}, []);

	function SelectCss() {
		try{
			switch (window.PaymentSDKInitializationData.GatewayName.toLowerCase()) {
				case "nmi":
					setGatewayStyle(React.lazy(() => import('./cssLoaders/nmiCssLoader')));
					break;
				case "braintree":
					setGatewayStyle(React.lazy(() => import('./cssLoaders/braintreeCssLoader')));
					break;
				case "ecommpay":
					setGatewayStyle(React.lazy(() => import('./cssLoaders/ecommpayCssLoader')));
					break;
				case "trustpayments":
					setGatewayStyle(React.lazy(() => import('./cssLoaders/trustpaymentsCssLoader')));
					break;
				case "paysafe":
					setGatewayStyle(React.lazy(() => import('./cssLoaders/paysafeCssLoader')));
					break;
				default:
					return;
			}
		}
		catch (err) {
			appInsights.trackException({ error: new Error(err), severityLevel: "Error" });
		}
	}

	return (
		<>
			<React.Suspense fallback={<></>}>
				{isSdkLoaded && GatewayStyle != null && <GatewayStyle />}
			</React.Suspense>
			<CostDetails
				selectedPaymentOption={selectedPaymentOption}
				setErrorMessage={setErrorMessage}
				setSuccessMessage={setSuccessMessage}
				startCheckout={startCheckout}
				checkoutResponse={checkoutResponse}
				paymentOptionClickHandler={paymentOptionClickHandler}
				isSdkLoaded={isSdkLoaded}
			/>
		</>
	);
}

export default Checkout;