import React, { useState, useEffect } from "react";
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import cookieHelper from '../helpers/CookieHelper';
import AnalyticsHelper from '../helpers/AnalyticsHelper';
import { useHistory } from 'react-router-dom';

const Confirmation = (props) => {
	const [contentHtml, setContentHtml] = useState("");
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	document.title = props.tabTitle;
	let history = useHistory();
	const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";

	if (!isPaymentCompleted) {
		history.replace("/extras");
	}

	async function GetConfirmationHtml(confirmationPageLink) {
		const token = localStorage.getItem("ECommerceToken");
		const bookingRef = localStorage.getItem("BookingRef");
		const leadLastName = localStorage.getItem("LeadLastName");
		const language = cookieHelper.getCookie("SelectedLanguageOption");
		const departureDate = localStorage.getItem("DepartureDate");

		return await axios.get(
			"/api/payment/getbookinghtml?responsivePageLink=" + encodeURI(confirmationPageLink) +
			"&bookingRef=" + bookingRef +
			"&leadLastName=" + leadLastName +
			"&language=" + language +
			"&departureDate=" + departureDate,
			{ 'headers': { 'token': token } }
		);
	}


	useEffect(() => {
		AnalyticsHelper.TrackPageLoad("confirmation_load", "Booking Confirmation");
	}, [])

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		}
	}, []);

	useEffect(() => {
		if (isMounted) {
			const confirmationPageLink = sessionStorage.getItem("confirmationPageLink");

			if (confirmationPageLink && confirmationPageLink !== "null") {
				sessionStorage.setItem("BookingStatus", "BookingCompleted");
				GetConfirmationHtml(confirmationPageLink)
					.then((response) => {
						if (response.data && response.data.document) {
							setContentHtml(response.data.document);
						}
					})
					.catch(() => {
						setError(true);
					})
					.finally(() => {
						setIsLoaded(true);
					});
			} else if (!confirmationPageLink || confirmationPageLink === "null") {
				setIsLoaded(true);
			}
		}
	}, [isMounted]);

	if (!isLoaded) {
		return <div className="content-container">
			<section className="w-100">
				<div className="mb-4"><Skeleton height={200} /></div>
				<div className="mb-4"><Skeleton height={400} /></div>
				<div className="mb-4"><Skeleton height={150} /></div>
				<div className="mb-4"><Skeleton height={200} /></div>
			</section>
		</div>;
	} else if (error || !contentHtml) {
		return (
			<main className="content-container">
				<section className="w-100">
					The booking was successful, however we had issues with displaying the confirmation. An email will be sent to you shortly.
				</section>
			</main>
		);
	} else {
		return (
			<section className="content-container">
				<section className="w-100">
					<meta
						name="viewport"
						content="width=device-width, initial-scale=0.3"
					/>
					<div
						className=""
						dangerouslySetInnerHTML={{ __html: contentHtml }}
					></div>
				</section>
			</section>
		);
	}
}
export default Confirmation;
