import React from 'react';
import './radioInput.css';

const RadioInput = (props) => {
	const { name, isSelected, clickHandler, children } = props;

	return (
		<label className="radio-input-wrap px-3 pt-3 px-md-0">
			<div className={"radio-input" + (isSelected ? " selected" : "")}>
				<div className="ri-selected-dot pointer"></div>
				<input type="radio" className="ri-hidden-input pointer"
					name={name}
					checked={isSelected}
					onChange={clickHandler}
				/>
			</div>
			<div className="ri-label">{children}</div>
		</label>
	)
}

export default RadioInput;