import React, { useEffect, useState } from 'react';
import Layout from './layout/Layout';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './custom.css';
import FullScreenLoading from './components/shared/loading/FullScreenLoading';
import { DeleteBookingCookie } from './services/AbandonedBookingService';
import { SetLanguage, GetEnvironment } from './services/BookingService';
import { PersistSelectedLanguage } from './services/EcommerceService';
import bookingHelper from './helpers/BookingHelper';
import useBus from 'use-bus'
import { LoadGoogleMapsScript } from './services/GoogleMapService';
import './helpers/TranslationsHelper';
import i18n from 'i18next';
import withAppInsights from './services/AppInsights';
import Routes from '././Routes';
import EcommerceHelper from './helpers/EcommerceHelper';
import cookieHelper from './helpers/CookieHelper';
import { useTranslation } from 'react-i18next';

const legalitiesUrls = ["/booking-conditions", "/website-terms-of-use", "/health-and-safety"];

function App() {
	const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";
	const [isLoaded, setIsLoaded] = useState(false);
	const [paymentSuccessful, setPaymentSuccessful] = useState(false);
	const [isSuccessful, setIsSuccessful] = useState(true);
	const [summaryData, setSummaryData] = useState({
		villaName: null,
		startDate: null,
		returnDate: null,
		mainImage: null
	});
	const [FontTheme, setFontTheme] = useState(null);
	const [globalErrorMessage, setGlobalErrorMessage] = useState(null);
	const [showSummary, setShowSummary] = useState(!isPaymentCompleted);
	const { t } = useTranslation();
	let history = useHistory();
	const isLegalityPage = legalitiesUrls.find((x) => { return x === history.location.pathname }) !== undefined;


	useBus('paymentSuccessful', () => {
		history.push("/booking-confirmation");
		setPaymentSuccessful(true);
	}, [paymentSuccessful]);

	useBus('AccommodationRenewalFailed', async () => {
		history.push("/error");
		setGlobalErrorMessage(t("RENEWAL_FAILED_MESSAGE"));
	})

	useBus('updateLanguage', async () => {
		let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");
		await PersistSelectedLanguage();
		SetLanguage(selectedLanguage).finally(() => {
			i18n.changeLanguage(selectedLanguage);
			setIsLoaded(false);
			history.go(0);
		})
	});

	useEffect(() => {
		window.addEventListener("storage", onBrowserContextChange);
		return () => {
			window.removeEventListener("storage", onBrowserContextChange)
		}
	}, []) 

	function onBrowserContextChange(e) {
		if(e.key === "BookingRef") {
			history.push("/error");
			setGlobalErrorMessage(t("RENEWAL_FAILED_MESSAGE"));
		}
	}

	useEffect(() => {
		return history.listen(location => {
			if (history.action === 'POP') {
				if (sessionStorage.getItem("AlternativeVillaMessageShown"))
					history.go(-1);
				else
					history.go(0);
			}
		})
	}, [history]);

	useEffect(() => {
		let language = cookieHelper.getCookie("SelectedLanguageOption");
		if (language == "el" || language == "bg") {
			setFontTheme(React.lazy(() => import('./fonts/SourceSansFont')));
		}
		else {
			setFontTheme(React.lazy(() => import('./fonts/DomusFont')));
		}
	}, [])

	useEffect(() => {
		if (globalErrorMessage) {
			console.error(globalErrorMessage);
			history.push("/error");
		}
	}, [globalErrorMessage, history]);

	useEffect(() => {
		setShowSummary(isSuccessful);
	}, [isLoaded, isSuccessful]);

	useEffect(() => {
		async function startBooking() {
			const selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");
			await PersistSelectedLanguage();
			let language = selectedLanguage ? selectedLanguage : "en";
			await SetLanguage(language).finally(() => {
				i18n.changeLanguage(language);
				document.documentElement.lang = language;
			})
			let alreadyRedirected = false;
			if (!isLegalityPage) {
				const existingReservationLink = localStorage.getItem("ReservationLink");
				axios.post("/booking/StartBooking", { existingReservationLink: existingReservationLink })
					.then((response) => {
						if (response !== null && response.data !== null && response.data !== "") {
							sessionStorage.clear();
							bookingHelper.initialiseLocalStorage(response.data);
							setIsLoaded(true);
						}
						else {
							bookingHelper.reservationRenewal(setGlobalErrorMessage, setIsLoaded);
						}
						setIsSuccessful(true);

					}).catch((error) => {
						setIsSuccessful(false);
						let message = null;
						if (error.response != null)
							message = error.response.data;

						if (message && message === "LateBooking") {
							history.push("/late-booking");
						}
						else {
							history.push("/alternativevilla", { data: message });
							sessionStorage.setItem("AlternativeVillaMessageShown", true); 
						}

						alreadyRedirected = true;
						setIsLoaded(true);
					}).finally(() => {
						let isContinuedBooking = cookieHelper.getCookie("ContinueBooking");

						if (isContinuedBooking) {
							cookieHelper.removeCookie("ContinueBooking");
						}

						DeleteBookingCookie();
						const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";
						if (isPaymentCompleted && !alreadyRedirected
							&& history.location.pathname !== "/paymentcompleted"
							&& history.location.pathname !== "/confirmation"
							&& history.location.pathname !== "/booking-confirmation") {
							history.push("/paymentcompleted");
						}
					})
			} else {
				GetEnvironment().then((response) => {
					localStorage.setItem("Environment", response.data);
				}).finally(() => {
					setIsLoaded(true);
				})
			}
		}
		startBooking();
	}, [history, isLegalityPage]);

	useEffect(() => {
		LoadGoogleMapsScript();
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			bookingHelper.reservationRenewal(setGlobalErrorMessage, setIsLoaded)
		}, 20 * 60 * 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		EcommerceHelper.checkAndRenewECommerceToken(setGlobalErrorMessage);

		const interval = setInterval(EcommerceHelper.checkAndRenewECommerceToken, 5 * 60 * 1000, setGlobalErrorMessage);
		return () => clearInterval(interval);
	}, []);


	if (!isLoaded) {
		return <FullScreenLoading />;
	}
	else {
		return (
			<Layout
				setSummaryData={setSummaryData}
				showSummary={showSummary}
			>
				<React.Suspense fallback={<></>}>
					<FontTheme />
				</React.Suspense>

				<Routes summaryData={summaryData} globalErrorMessage={globalErrorMessage} />
			</Layout>
		)
	}
}

export default withAppInsights(App);