import React from 'react';
import { Route, Switch } from 'react-router';
import { useTranslation } from 'react-i18next';
import Extras from './views/Extras';
import AlternativeVilla from './views/AlternativeVilla';
import Payment from './views/Payment';
import Error from './views/Error';
import ContactDetails from './views/ContactDetails';
import Confirmation from './views/Confirmation';
import ConfirmationWithDD from './views/ConfirmationWithDD';
import PaymentCompleted from './views/PaymentCompleted';
import BookingConditions from './views/BookingConditions';
import TermsOfUse from './views/TermsOfUse';
import HealthAndSafety from './views/HealthAndSafety';
import LateBooking from './views/LateBooking';


function Routes(props) {
	let summaryData = props.summaryData;
	let globalErrorMessage = props.globalErrorMessage;
	const { t } = useTranslation();

	return (
		<Switch>
			<Route exact path="/" render={() => (
				<Extras tabTitle={"Villa Plus | " + t("EXTRAS_HEADER_TITLE")} />
			)} />
			<Route exact path="/extras" render={() => (
				<Extras tabTitle={"Villa Plus | " + t("EXTRAS_HEADER_TITLE")} />)
			} />
			<Route exact path="/contactdetails" render={() => (
				<ContactDetails summaryData={summaryData} tabTitle={"Villa Plus | " + t("CONTACT_DETAILS_HEADER_TITLE")} />
			)} />
			<Route exact path="/payment" render={(props) => (
				<Payment {...props} summaryData={summaryData} tabTitle={"Villa Plus | " + t("PAYMENT_HEADER_TITLE")} />
			)} />
			<Route path="/late-booking" render={(props) => (
				<LateBooking tabTitle={"Villa Plus | " + t("LATE_BOOKING_HEADER_TITLE")} />
			)} />
			<Route path="/alternativevilla" render={(props) => (
				<AlternativeVilla {...props} tabTitle={"Villa Plus | " + t("ALTERNATIVE_VILLA_HEADER_TITLE")} />
			)} />
			<Route path="/confirmation" render={() => (
				<Confirmation tabTitle={"Villa Plus | " + t("CONFIRMATION_HEADER_TITLE")} />
			)} />
			<Route path="/booking-confirmation" render={() => (
				<ConfirmationWithDD />
			)} />
			<Route path="/paymentcompleted" render={() => (
				<PaymentCompleted tabTitle={"Villa Plus | " + t("PAYMENT_COMPLETED_HEADER_TITLE")} />
			)} />
			<Route path="/booking-conditions" render={() => (
				<BookingConditions tabTitle={"Villa Plus | " + t("BOOKING_CONDITIONS_HEADER_TITLE")} />
			)} />
			<Route path="/website-terms-of-use" render={() => (
				<TermsOfUse tabTitle={"Villa Plus | " + t("TERMS_OF_USE_HEADER_TITLE")} />
			)} />
			<Route path="/health-and-safety" render={() => (
				<HealthAndSafety tabTitle={"Villa Plus | " + t("HEALTH_AND_SAFETY_HEADER_TITLE")} />
			)} />
			<Route path="/error" render={() => (
				<Error errorMessage={globalErrorMessage} tabTitle={"Villa Plus | " + t("ERROR_HEADER_TITLE")} />)
			} />
			<Route path='/*' component={() => {
				window.location.href = localStorage.getItem("Environment") === "production" ? 'https://www.villaplus.com' : 'https://www.villaplustest.com';
				return null;
			}} />
		</Switch>

	)
}

export default Routes;