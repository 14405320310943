import cookieHelper from '../helpers/CookieHelper';

const languages = {
    "en": "en-GB",
    "en-US": "en-US",
    "nl": "nl",
    "de": "de",
    "es": "es",
    "fr": "fr",
    "pt": "pt",
    "it": "it",
    "hr": "hr",
    "da": "da-DK",
    "pl": "pl",
    "lt": "lt",
    "fi": "fi",
    "et": "et",
    "nb": "nb",
    "hu": "hu",
    "el": "el-gr",
    "bg": "bg",
    "cs": "cs",
    "ro": "ro",
    "sv": "sv-se",
    "sr": "sr-Latn",
    "sk": "sk",
    "lv": "lv",
    "mt": "mt",
    "sl": "SL"
}

export default {
    formatPrice: (price) => {
        let currencyCode = localStorage.getItem("CurrencyCode");
        const selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

        if (!currencyCode && selectedLanguage) {
            currencyCode = selectedLanguage == "en" ? "GBP" : "EUR"
        }
        else if (!currencyCode && !selectedLanguage)
            currencyCode = "GBP";

        let formatting = {
            style: "currency",
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }

        if (currencyCode === "USD")
            formatting.currencyDisplay = "narrowSymbol";

        return (
            new Intl.NumberFormat(languages[selectedLanguage], formatting).format(price)
        );
    },

    formatDate: (date, formatOptions) => {
        formatOptions = formatOptions || {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "2-digit"
        };

        let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

        if (!selectedLanguage || selectedLanguage === "")
            selectedLanguage = "en";

        const languageToFormat = languages[selectedLanguage];

        date = new Date(date);

        return (
            new Intl.DateTimeFormat(languageToFormat, formatOptions).format(date)
        );
    },

    formatGuests: (guests, adultsLabel, childrenLabel, infantsLabel) => {
        if (!guests) return "";

        return (
            guests.Adults + " " + adultsLabel + 
            (guests.Children !== 0 ? "  ·  " + guests.Children + " " + childrenLabel  : "") +
            (guests.Infants !== 0 ? "  ·  " + guests.Infants + " " + infantsLabel : "")
        );
    }
}