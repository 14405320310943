import React from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import CloseButton from '../../../images/close-button.svg';
import './addressRestrictionErrorModal.css';
import { useTranslation } from 'react-i18next';


const AddressRestrictionErrorModal = (props) => {
    let { message, onToggle, isOpen} = props;
    const { t } = useTranslation();

    const ref = useDetectClickOutside({ onTriggered: closeModal });

    function handleToggle(value) {
        onToggle(value);
    }

    function closeModal() {
        handleToggle(false);
    }

    if (!message) {
        return null;
    }
    else if (isOpen == false) {
        return null;
    }
    else {
		return (
            <div className="show modal" id="errorModal">
                <div className="modal-dialog">
                    <div ref={ref} className="modal-content">
                        <div className="modal-header">
                            <span className="modal-title">{t("ADDRESS_ERROR_MODAL_TITLE")}</span>
                            <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src={CloseButton} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{t("ADDRESS_NOT_SUPPORTED")}</p>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

export default AddressRestrictionErrorModal;