import React, { useEffect, useState, useRef } from 'react';
import PageTitle from '../shared/PageTitle';
import { GetLead, SaveLead } from '../../services/ContactDetailsService';
import { SaveDataInDotDigital } from '../../services/DotDigitalService';
import { SetBookingCookie } from '../../services/AbandonedBookingService';
import Navigation from '../shared/navigation/Navigation';
import "./lead.css";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import InformationIcon from '../../images/information-icon.svg';
import ValidationIcon from '../../images/validation-icon.svg';
import TextInput from '../shared/controls/TextInput';
import ErrorMessage from '../shared/errorMessage/ErrorMessage';
import AddressRestrictionErrorModal from '../shared/errorModal/AddressRestrictionErrorModal';
import Skeleton from 'react-loading-skeleton';
import GoogleAddressLookup from './GoogleAddressLookup';
import CountryCode from './CountryCode';
import PostcodeHelper from '../../helpers/PostcodeHelper';
import '../shared/controls/checkboxInput.css';
import FullScreenLoading from '../shared/loading/FullScreenLoading';
import WheelChairIcon from '../../images/wheelchair-icon.svg'
import { useTranslation } from 'react-i18next';
import TotalPrice from '../summary/TotalPrice';
import cookieHelper from '../../helpers/CookieHelper';
import localisationHelper from '../../helpers/LocalisationHelper';
import { geocodeByAddress } from 'react-places-autocomplete';
import AppInsightsHelper from '../../helpers/AppInsightsHelper';
import CountryCodesEnglish from '../../jsonData/Translations/countryCodes.en.json';
import useBus, { dispatch } from 'use-bus';
import { useHistory } from 'react-router-dom';
import { appInsights } from '../../services/AppInsights';
import { useLatest } from 'react-use';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import useABTest from '../../hooks/useABTest';

const Lead = (props) => {
	const selectedCountryCodeDetails = setDefaultCountryCode();
	const [isLoaded, setIsLoaded] = useState(false);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again.");
	const [openErrorModal, setOpenErrorModal] = useState(false);
	const [showAdultError, setShowAdultError] = useState(false);
	const [lead, setLead] = useState({ email: "", firstName: "", lastName: "", phoneNumber: "", address: { countryCode: "", state: "", address1: "", postcode: "" }, update: "", emailLanguage: cookieHelper.getCookie("SelectedLanguageOption") });
	const [triggerValidation, setTriggerValidation] = useState(false);
	const [showValidationMsg, setShowValidationMsg] = useState(false);
	const [isVscChecked, setIsVscChecked] = useState(false);
	const [isAdultChecked, setIsAdultChecked] = useState(false);
	const [addressString, setAddressString] = React.useState("");
	const [onlyPhoneNumber, setOnlyPhoneNumber] = React.useState("");
	const [selectedCountryCode, setSelectedCountryCode] = React.useState({ key: selectedCountryCodeDetails.key, code: selectedCountryCodeDetails.code });
	const [isSubmissionInProgress, setIsSubmissionInProgress] = React.useState(false);
	const { t } = useTranslation();
	const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";
	const servicedBySupplier = sessionStorage.getItem("ServicedBySupplier") === "true";
	const isLocalhost = window.location.href.includes('localhost') ? true : false;
	let history = useHistory();

	var isAdult;
	let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

	//These are required for event listeners
	const latestLead = useLatest(lead);
	const latestAddressString = useLatest(addressString);
	const latestSummaryData = useLatest(props.summaryData);
	/***************************************************** */

	function handleChange(propertyName, value) {
		if (propertyName === "email") {
			value = value.toLowerCase();
		}
		if (propertyName === "address") {
			setAddressDetails(value);
		}
		else if (propertyName === "phoneNumber") {
			setOnlyPhoneNumber(value);
			localStorage.setItem("PhoneNumber", value);
			setLead(prevState => ({
				...prevState,
				phoneNumber: selectedCountryCode.code + value
			}));
		}
		else {
			setLead({
				...lead,
				[propertyName]: value
			});
		}
	}

	async function setAddressDetails(value) {
		let geocodeByAddressResponse;
		let countryCode;
		let administrativeAreaLevel1;
		let state;
		var postcodes = PostcodeHelper.ExtractPostcode(value);
		let postcode = (postcodes) ? postcodes[0] : "";
		let addressWithoutPostcode = PostcodeHelper.GetAddressWithoutPostcode(value, postcode);

		if (value != "") {
			if (!isLocalhost) {
				await geocodeByAddress(value)
					.then(results => geocodeByAddressResponse = results[0])
					.catch(error => console.log("Api can't find this location"));
			}
			if (typeof (geocodeByAddressResponse) != "undefined") {
				geocodeByAddressResponse.address_components.forEach(function (addressComponent) {
					if (addressComponent.types.includes("country"))
						countryCode = addressComponent.short_name;
					else if (addressComponent.types.includes("administrative_area_level_1"))
						administrativeAreaLevel1 = addressComponent.short_name;
					else if (addressComponent.types.includes("postal_code"))
						postcode = addressComponent.short_name;
				});

				if (!addressWithoutPostcode || addressWithoutPostcode == '')
					addressWithoutPostcode = geocodeByAddressResponse.formatted_address;

				if (countryCode == "US")
					state = administrativeAreaLevel1;

				if (typeof (countryCode) != "undefined")
					lead.address.countryCode = countryCode;
				else
					lead.address.countryCode = "UnsupportedCountry";
				lead.address.state = state;
				lead.address.address1 = addressWithoutPostcode;
				lead.address.postcode = postcode

			}
			else {
				if (!isLocalhost) {
					lead.address.countryCode = "InvalidAddress";
					lead.address.address1 = addressWithoutPostcode;
					lead.address.postcode = postcode;
				}
				else {
					lead.address.countryCode = "UK";
					lead.address.address1 = addressWithoutPostcode;
					lead.address.postcode = postcode;
				}
			}
		}
		else {
			lead.address.countryCode = "";
			lead.address.state = "";
			lead.address.address1 = "";
			lead.address.postcode = "";
		}


		setAddressString(value);
		appInsights.trackEvent({
			name: "setAddressDetails finished",
			properties: {
				bookingRef: localStorage.getItem("BookingRef")
			}
		});
	}

	function setDefaultCountryCode() {
		var details = localisationHelper.fetchCountryPhoneDetails();
		if (details)
			return { key: details.key, code: details.code }
		return { key: 'GB', code: '+44' };
	}

	function setPhoneWithoutCC(phone, countryCode) {
		if (phone && countryCode) {
			let phoneWithoutCC = phone.replace(countryCode.code, "");
			setOnlyPhoneNumber(phoneWithoutCC);
		}
	}

	async function handleSubmit() {
		await setAddressDetails(addressString);
		setIsSubmissionInProgress(true);
		if (validateData(lead)) {
			isAdult = true;
			if (isAdult) {
				return SaveLead(lead)
					.then(response => {
						localStorage.setItem("LeadFirstName", lead.firstName);
						localStorage.setItem("LeadLastName", lead.lastName);
						localStorage.setItem("LeadEmail", lead.email);
						localStorage.setItem("LeadFullName", lead.firstName + " " + lead.lastName);
						sessionStorage.setItem("Address", addressString);
						AnalyticsHelper.TrackLeadSubmission();
						let eCommerceResource = localStorage.getItem("ECommerceBookingLink");
						SaveDataInDotDigital(lead, eCommerceResource, props.summaryData);
						SetBookingCookie().catch(error => console.error(error));
						return true;
					})
					.catch(error => {
						setIsSubmissionInProgress(false);
						const message = error.response.data.message;
						if (message == "Booking of resources from this region is restricted.")
							setOpenErrorModal(true);
						else
							setIsError(true);
						setErrorMessage(message);
						sessionStorage.setItem("Address", addressString);
					});
			}
			else {
				setShowAdultError(true);
				setIsSubmissionInProgress(false);
				return new Promise((resolve) => {
					resolve();
				});
			}
		}
		else {
			setTriggerValidation(true);
			setShowValidationMsg(true);
			setIsSubmissionInProgress(false);
			return new Promise((resolve) => {
				resolve();
			});
		}

	};

	async function handleNewCTASubmit() {
		await setAddressDetails(latestAddressString.current);
		setIsSubmissionInProgress(true);
		if (validateData(latestLead.current)) {
			isAdult = true;
			if (isAdult) {
				return SaveLead(latestLead.current)
					.then(response => {
						//TODO: Remove after testing
						appInsights.trackEvent({
							name: "handleSubmit promise hit",
							properties: {
								bookingRef: localStorage.getItem("BookingRef"),
								isAddressStringNull: latestAddressString === null,
								isAddressCurrentNull: latestAddressString != null && latestAddressString.current == null
							}
						});
						localStorage.setItem("LeadFirstName", latestLead.current.firstName);
						localStorage.setItem("LeadLastName", latestLead.current.lastName);
						localStorage.setItem("LeadEmail", latestLead.current.email);
						localStorage.setItem("LeadFullName", latestLead.current.firstName + " " + latestLead.current.lastName);
						sessionStorage.setItem("Address", latestAddressString.current);
						AnalyticsHelper.TrackLeadSubmission();
						let eCommerceResource = localStorage.getItem("ECommerceBookingLink");
						SaveDataInDotDigital(latestLead.current, eCommerceResource, latestSummaryData.current);
						SetBookingCookie().catch(error => console.error(error));
						history.push("/payment");
						sessionStorage.setItem("contactDetails", true);
						dispatch({ type: 'onNavigation', payload: "/payment" });
						return true;
					})
					.catch(error => {
						setIsSubmissionInProgress(false);
						const message = error.response.data.message;
						if (message == "Booking of resources from this region is restricted.")
							setOpenErrorModal(true);
						else
							setIsError(true);
						setErrorMessage(message);
						sessionStorage.setItem("Address", addressString);
						appInsights.trackException({ error: new Error(error), severityLevel: "Error" });
						console.error(error);
					});
			}
			else {
				setShowAdultError(true);
				setIsSubmissionInProgress(false);
				return new Promise((resolve) => {
					resolve();
				});
			}
		}
		else {
			setTriggerValidation(true);
			setShowValidationMsg(true);
			setIsSubmissionInProgress(false);
			return new Promise((resolve) => {
				resolve();
			});
		}

	};

	function validateData(lead) {
		if (!document.querySelector("input[type=email]").checkValidity()) return false;
		for (let key in lead) {
			if (key !== "address") {
				if (lead[key].trim() === "")
					return false;
			}
			else {
				switch (lead[key].countryCode.trim()) {
					case "":
						return false;
					case "InvalidAddress":
						setErrorMessage(t("INVALID_ADDRESS"));
						setIsError(true);
						return false;
					case "UnsupportedCountry":
						setErrorMessage(t("ADDRESS_NOT_SUPPORTED"));
						setIsError(true);
						return false;
					case "US":
						if (typeof lead[key].state == "undefined") {
							setErrorMessage(t("INVALID_USA_ADDRESS"));
							setIsError(true);
							return false;
						}		
				}
				if (lead[key].address1.trim() === "")
					return false;
			}
		}
		return true;
	}

	function onVillaSuitabilityChange() {
		sessionStorage.setItem("vscChecked", !isVscChecked);
		setIsVscChecked(!isVscChecked);
	}

	function onAdultCheckChange() {
		sessionStorage.setItem("isAdult", !isAdultChecked);
		setIsAdultChecked(!isAdultChecked);
		setShowAdultError(true);
	}

	function handleSelectedAddress(selectedAddress) {
		setAddressString(selectedAddress);
	}

	function onErrorModalToggle(value) {
		setOpenErrorModal(value);
	}

	function MergePhoneAndCC() {
		let keyCode = JSON.parse(sessionStorage.getItem("countryCode"));
		setLead(prevState => ({
			...prevState,
			phoneNumber: keyCode.code + onlyPhoneNumber
		}));
		setSelectedCountryCode({ key: keyCode.key, code: keyCode.code });
	}

	useBus('onForwardNavigation', () => {
		handleNewCTASubmit();
	});

	useEffect(() => {
		if (!isPaymentCompleted) {
			let keyCode = JSON.parse(sessionStorage.getItem("countryCode"));
			if (keyCode)
				setSelectedCountryCode({ key: keyCode.key, code: keyCode.code });
			GetLead()
				.then((response) => {
					if (response !== null && response.data !== null && response.data !== "") {
						if (response.data.firstName) {
							setLead(prevState => ({
								...prevState,
								firstName: response.data.firstName,
								lastName: response.data.lastName,
								email: response.data.email,
								phoneNumber: response.data.phoneNumber,
								update: response.data.update
							}));
							let address = sessionStorage.getItem("Address");
							if (address) {
								setAddressDetails(address);
								setAddressString(address);
							}

							setPhoneWithoutCC(response.data.phoneNumber, keyCode);

							(sessionStorage.getItem("vscChecked") === "true") ? setIsVscChecked(true) : setIsVscChecked(false);
							(sessionStorage.getItem("isAdult") === "true") ? setIsAdultChecked(true) : setIsAdultChecked(false);
						}
						else {
							setLead(prevState => ({
								...prevState,
								update: response.data.update
							}));
						}
					}
					else { setIsError(false); }
				})
				.catch(error => {
					setIsError(true);
					setIsLoaded(true);
				})
				.finally(() => {
					setIsLoaded(true);
				})
		}
	}, []);

	let content = (
		<>
			{
				(isLoaded &&
					<div className="mt-4">
						<div className="display">
							<div className="position-relative mb-4 firstname w-100">
								<TextInput
									onChange={(e) => handleChange("firstName", e.target.value)}
									placeholder={t('FIRST_NAME')}
									triggerValidation={triggerValidation}
									showValidationMsg={showValidationMsg}
									value={lead.firstName}
									missingValueMessage={t("MISSING_FIRST_NAME")}
									minLength="2" />
							</div>
							<div className="position-relative mb-4 w-100">
								<TextInput onChange={(e) => handleChange("lastName", e.target.value)}
									placeholder={t('LAST_NAME')}
									triggerValidation={triggerValidation}
									showValidationMsg={showValidationMsg}
									value={lead.lastName}
									missingValueMessage={t("MISSING_LAST_NAME")}
									minLength="2" />
							</div>
						</div>

						<div className="position-relative mb-4">
							{isLocalhost ?
								<TextInput onChange={(e) => handleChange("address", e.target.value)}
									placeholder={t('POSTCODE')}
									triggerValidation={triggerValidation}
									showValidationMsg={showValidationMsg}
									missingValueMessage={t("MISSING_ADDRESS")}
									value={addressString} />
								:
								<GoogleAddressLookup selectedAddress={handleSelectedAddress} triggerValidation={triggerValidation} showValidationMsg={showValidationMsg} addressValue={addressString} missingValueMessage={t("MISSING_ADDRESS")} />
							}
						</div>
						<div className="position-relative mb-4">
							<TextInput onChange={(e) => handleChange("email", e.target.value)}
								placeholder={t('EMAIL_ADDRESS')}
								triggerValidation={triggerValidation}
								showValidationMsg={showValidationMsg}
								value={lead.email}
								maxLength="50"
								missingValueMessage={t("MISSING_EMAIL_ADDRESS")}
								type="email"
								pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}"
							/>
						</div>
						<div className="display">
							<div className="position-relative mb-4 country-code-input">
								<CountryCode handleCountryCodeChange={MergePhoneAndCC} />
							</div>
							<div className="position-relative mb-4 phone-number-input">
								<TextInput onChange={(e) => handleChange("phoneNumber", e.target.value)}
									placeholder={t('MOBILE_NUMBER')}
									triggerValidation={triggerValidation}
									showValidationMsg={showValidationMsg}
									value={onlyPhoneNumber}
									type="number"
									missingValueMessage={t("MISSING_MOBILE_NUMBER")}
									minLength="10" />
							</div>
						</div>
						{!servicedBySupplier &&
							<div className="mb-3 d-flex position-relative">
								<div>
									<img className="ml-5 pb-1 align-middle" src={WheelChairIcon} alt="Special needs" />
								</div>
								<div>
									<label className="checkbox-container"> <span className="pl-2 align-top">{t("VILLA_SUITABILITY_CHECKBOX")}</span>
										<input id="vscCheckbox" type="checkbox" checked={isVscChecked}
											onChange={onVillaSuitabilityChange} /><span className="checkmark lead-checkbox"></span>
									</label>
								</div>
								<div>
									<Popup trigger={<img src={InformationIcon}
										alt="Information icon" className="ml-1 pb-1 pointer" />}
										position="bottom center"
										onOpen={ () => AnalyticsHelper.TrackInfoBox("Villa Suitability") }
									>
										{t("VILLA_SUITABILITY_DESCRPTION")}
									</Popup>
								</div>
							</div>
						}
						<div className="information">
							{t("LEAD_DISCLAIMER_CONTENT_LINE_ONE")}
							<br />
							{t("LEAD_DISCLAIMER_CONTENT_LINE_TWO")}
						</div>
					</div>
				) ||
				<>	<Skeleton count="5" height={32} className="mt-4" />	</>
			}
		</>
	)
	return (
		<>
			{
				isSubmissionInProgress && <FullScreenLoading />
			}
			<div className="card colored-bottom">
				<div className="card-body">
					{<AddressRestrictionErrorModal onToggle={onErrorModalToggle} isOpen={openErrorModal} message={errorMessage} />}
					{isError && <ErrorMessage message={errorMessage} />}
					<PageTitle title={t("LEAD_GUEST").toLowerCase()} />
					{content}
				</div>
			</div>
			<div className="row justify-content-between bottom-navigation d-flex d-md-none" style={{ bottom: "0" }}>
				<div className="col-auto" style={{ alignContent: "center" }}>
					<TotalPrice />
				</div>
				<Navigation buttonKey="CONTACT_DETAILS_FORWARD_BUTTON" pathToNextPage="/payment" commitFunction={handleSubmit} completedStep="contactDetails" />
			</div>
			<div className="d-none d-md-flex">
				<TotalPrice />
				<div className="mt-0 mb-3">
					<Navigation buttonKey="CONTACT_DETAILS_FORWARD_BUTTON" pathToNextPage="/payment" commitFunction={handleSubmit} completedStep="contactDetails" />
				</div>
			</div>
		</>
	);
}
export default Lead;