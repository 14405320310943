import React, { useState, useEffect } from 'react';
import useBus from 'use-bus';
import { useTranslation } from 'react-i18next';
import formatHelper from '../../helpers/FormatHelper';
import useABTest from '../../hooks/useABTest';



const TotalPrice = () => {

	const [totalCost, setTotalCost] = useState(localStorage.getItem("TotalCost"));
	const { t } = useTranslation();

	useBus("updateTotalCost", () => setTotalCost(localStorage.getItem("TotalCost")), [totalCost]);

	let content;

	content = (
		<>
			<div className="row d-block d-md-none">
				<div className="col-12 pr-4">
					<div className="pb-total-price pl-1 bottom-price-line-height">
						{
							t("BASKET_TOTAL")
						}
					</div>
					<div className="pb-total-price pb-total-price-number pl-1 bottom-price-line-height">
						{formatHelper.formatPrice(totalCost)}
					</div>
				</div>
			</div>
		</>
	)

	return content;
}

export default TotalPrice;