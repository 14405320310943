import React from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { dispatch } from 'use-bus';

const BreadcrumbTrail = (props) => {
	const { title, activePage, displayExtras } = props;
	let history = useHistory();
	const { t } = useTranslation();
	const bookingStepUrls = ["/", "/extras", "/contactdetails", "/payment"];
	const isBookingStep = bookingStepUrls.find((x) => { return x === history.location.pathname }) != undefined;

	function isPreviousStepCompleted(nextStep) {
		let stepIsCompleted = null;

		if (nextStep === "/contactdetails") {
			stepIsCompleted = sessionStorage.getItem("extras") === "true";
			if (!stepIsCompleted)
				return false;
		}
		if (nextStep === "/payment") {
			stepIsCompleted = sessionStorage.getItem("contactDetails") === "true";
			if (!stepIsCompleted)
				return false;
		}

		return true;
	}

	function navigate(path) {
		const isCompleted = isPreviousStepCompleted(path);
		if (isCompleted) {
			dispatch({ type: 'onNavigation', payload: path });
			history.push(path);
		}
	}

	return (
		<div className="breadcrumb-trail">
			<p className="mr-0 mr-md-3 mb-0 pb-md-1 pb-0 page-title text-nowrap">{t(title)}</p>
			{isBookingStep ? 
				<div className="bt-steps-wrap pt-0 pt-md-1">
				{displayExtras ?
					<div onClick={() => navigate("/extras")}
						className={(activePage === "/extras" || activePage === "/" ? "active" : "") + " pointer bt-step"}
					>1</div>
					:
					null
				}
				<div onClick={() => navigate("/contactdetails")}
					className={(activePage === "/contactdetails" ? "active" : "") + " pointer bt-step"}
				>{displayExtras ? 2 : 1}</div>
				<div onClick={() => navigate("/payment")}
					className={(activePage === "/payment" ? "active" : "") + " pointer bt-step"}
				>{displayExtras ? 3 : 2}</div>
				</div>
				:
				null
				}
		</div>
	)
}

export default BreadcrumbTrail;