import React, { useEffect } from "react";
import { dispatch } from 'use-bus'
import ConfirmationHeader from '../components/confirmation/ConfirmationHeader';
import ConfirmationBody from '../components/confirmation/ConfirmationBody';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AnalyticsHelper from '../helpers/AnalyticsHelper';


const ConfirmationWithDD = () => {
	let history = useHistory();
	const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";

	if (!isPaymentCompleted) {
		history.replace("/extras");
	}

	const { t } = useTranslation();
	document.title = "Villa Plus | " + t("CONFIRMATION_HEADER_TITLE");

	useEffect(() => {
		AnalyticsHelper.TrackPageLoad("confirmation_load", "Booking Confirmation");
	}, [])

	useEffect(() => {
		sessionStorage.setItem("BookingStatus", "BookingCompleted");
		dispatch("useConfirmationPageTitle");
	}, []);

	return (
		<section className="booking-confirmation">
			<ConfirmationHeader />
			<ConfirmationBody />
		</section>
	);
}
export default ConfirmationWithDD;
