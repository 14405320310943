import axios from 'axios';
import cookieHelper from '../helpers/CookieHelper';

async function SetLanguage(selectedLanguage) {
	cookieHelper.setStrictCookie("SelectedLanguageOption", selectedLanguage, 365);

	if (selectedLanguage == "en-US")
		selectedLanguage = "en";

	return await axios.get("Booking/SetLanguage?culture=" + selectedLanguage);
}

async function GetEnvironment() {
	return await axios.get("Booking/GetEnvironment");
}

export { SetLanguage, GetEnvironment }