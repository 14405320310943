import React from 'react';
import WarningIcon from '../../../images/warning-icon.svg';
import './errorMessage.css';

const ErrorMessage = (props) => {
	let { message, marginClass, success } = props;

	if (success === undefined) {
		success = false;
	}

	if (!marginClass) {
		marginClass = "mb-4";
	}

	if (!message) {
		return null;
	} else {
		return (
			<div className={"error-message " + marginClass + (success ? " success" : "")}>
				<div className="em-icon-col">
					<img className="em-icon" alt="Error" src={WarningIcon} />
				</div>
				<div className="em-text-col" dangerouslySetInnerHTML={{ __html: message }}>
				</div>
			</div>
		);
	}
}

export default ErrorMessage;