import axios from 'axios';

async function RenewReservation() {
	const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";
	const accommodationBookingResource = localStorage.getItem("AccommodationBookingResource");
	const bookingRef = localStorage.getItem("BookingRef");
	const guestCount = localStorage.getItem("Guests");

	if (!isPaymentCompleted && accommodationBookingResource && guestCount)
		return await axios.post("api/AccommodationBooking/RenewReservation", {
			accommodationResource: accommodationBookingResource,
			guestCount: JSON.parse(guestCount),
			bookingRef: bookingRef
		});

	return;
}

export { RenewReservation }