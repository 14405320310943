import React, { useState, useEffect } from 'react';
import PageTitle from '../../shared/PageTitle';
import { GetAvailableExtras, AddExtra, GetExtrasInformation, ClearExtra } from '../../../services/AccommodationExtrasService';
import useBus, { dispatch } from 'use-bus'
import ErrorMessage from '../../shared/errorMessage/ErrorMessage';
import { Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import AccommodationExtra from './AccommodationExtra';

import ZBedImage from "../../../images/Extras/z-bed.png"
import StarterPackImage from "../../../images/Extras/starter-pack.png"
import CotImage from "../../../images/Extras/cot.png"
import HighChairImage from "../../../images/Extras/high-chair.png"
import PoolFenceImage from "../../../images/Extras/pool-fence.png"


const AccommodationExtras = () => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("Accommodation extras could not be loaded at this time.");
	const [extras, setExtras] = useState([]);
	const { t } = useTranslation();
	const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";
	const [isTransfersSet, setIsTransfersSet] = useState(false);
	const [hasTransfers, setHasTransfers] = useState(true);


	const extrasMapping = [
		{ type: "ZBed", code: "Z_BED", image: ZBedImage },
		{ type: "FreeStarterPack", code: "FREE_STARTER_PACK", image: StarterPackImage  },
		{ type: "StarterPack", code: "STARTER_PACK", image: StarterPackImage  },
		{ type: "Cot", code: "COT", image: CotImage },
		{ type: "HighChair", code: "HIGH_CHAIR", image: HighChairImage },
		{ type: "PoolFence", code: "POOL_FENCE", image: PoolFenceImage }
	];

	useEffect(() => {
		if (!isPaymentCompleted) {
			GetExtras();
		}
	}, []);


	useBus('onCurrencyChanged', () => {
		GetExtras();
	}, [extras]);

	function MapExtrasTranslationAndImage(accommodationExtras) {
		accommodationExtras.forEach((extra) => {
			let extraToBeMapped = extrasMapping.find((x => { return x.type.toLowerCase() === extra.type.toLowerCase() }));

			if (extraToBeMapped) {
				extra.translationKey = extraToBeMapped.code;
				extra.image = extraToBeMapped.image;
			}
		})
	}

	function MapExtraInformation(extrasInformation, extrasList) {
		extrasInformation.forEach((y) => {
			var extraToBeMapped = extrasList.find((x) => { return x.type === y.extra });
			if (extraToBeMapped)
				extraToBeMapped.information = y.information;

		});
		setExtras(extrasList);
		setIsLoaded(true);
	}

	function GetExtras() {
		GetAvailableExtras()
			.then((response) => {
				if (response && response.data) {
					MapExtrasTranslationAndImage(response.data);
					setExtras(response.data);
					GetExtrasInformation(response.data).then((extraInformationResponse) => {
						MapExtraInformation(extraInformationResponse.data, response.data);
					})
					setIsError(false);
				} else {
					setIsError(true);
				}
			})
			.catch(error => {
				console.error(error);
				setIsError(true);
				setIsLoaded(true);
			});
	}

	function updateSelectedCount(extra, count) {
		var extraIndex = extras.findIndex((x) => {
			return x.description === extra.description || x.type === extra.type
		});

		extras[extraIndex].selectedItemsCount = extras[extraIndex].selectedItemsCount + count;
		setExtras(extras);
	}

	function removeSelectedExtra(extra, amountToRemove) {
		setErrorMessage("")
		setIsError(false);
		return ClearExtra(extra.type, amountToRemove)
			.then(() => {
				updateSelectedCount(extra, -amountToRemove);
				dispatch("updateInvoice");
				return true;
			})
			.catch(error => {
				setIsError(true);
				setErrorMessage("Could not remove accommodation extras at this time. Please try again later.");
				return false;
			});
	}

	function addExtra(extra, count) {
		setErrorMessage("")
		setIsError(false);
		return AddExtra(extra.reservationAction, count)
			.then(() => {
				updateSelectedCount(extra, count);
				dispatch("updateInvoice");
				return true;
			})
			.catch(error => {
				setIsError(true);
				setErrorMessage("Could not update accommodation extras at this time. Please try again later.");
				return false;
			});
	}

	useBus("transfersSet", () => {
		setTimeout(() => {
			setHasTransfers(sessionStorage.getItem("TransfersAvailable").toLowerCase() === 'true' ? true : false);
			setIsTransfersSet(true);
		}, 100);
	});

	let content = (
		<div className="row ab-extras-content-wrapper">
			{
				isLoaded && extras.map((extra, index) => {
					return (
						<AccommodationExtra
							key={index}
							extra={extra}
							addExtra={addExtra}
							removeSelectedExtra={removeSelectedExtra}
							updateSelectedCount={updateSelectedCount}
							index={index}
						/>
					)
				})
			}
		</div>
	)

	if (isLoaded && !isError && isTransfersSet && !hasTransfers && extras.length === 0) {
		dispatch("noExtras");
		return <Redirect to="/contactdetails" />
	}
	else {
		return (
			isLoaded && extras.length === 0 && <></>
			||
			<div className="card colored-bottom mb-3 ab-extras-collapsible-container" >
				<div className="card-body ab-extras-card-body">
					{
						isError && <ErrorMessage message={errorMessage} />
					}
					<div className="ab-extras-collapsed-container d-flex flex-row justify-content-between align-items-center">
						<div className="ab-ec-title-container">
							<PageTitle title={t("ACCOMMODATION_EXTRAS_CARD_TITLE").toLowerCase()} />
						</div>
					</div>
					{content}
				</div>
			</div>
		);
	}
}

export default AccommodationExtras;