import React, { useState, useEffect } from 'react';
import CloseButton from '../shared/CloseButton';
import MainImage from './MainImage';
import ImageThumbnails from './ImageThumbnails';
import './imagesSlider.css';

const ImagesSlider = (props) => {
	const { images, isActive, setSliderActive, fullscreenBreakpoint, fullScreenAvailable } = props;
	const [activeImageIndex, setActiveImageIndex] = useState(null);
	const [prevActiveImages, setPrevActiveImages] = useState([]);

	useEffect(() => {
		if (images && images.images && images.images.length > 0) {
			setActiveImageIndex(parseInt(images.images[0].orderIndex));
		}
	}, [images]);

	function changeActiveImageTo(newIndex) {
		newIndex = parseInt(newIndex);

		if (!prevActiveImages.includes(activeImageIndex)) {
			prevActiveImages.push(activeImageIndex);
		}

		const minIndex = parseInt(images.images[0].orderIndex);
		const maxIndex = parseInt(images.images[images.images.length - 1].orderIndex);

		if (newIndex > maxIndex) {
			newIndex = minIndex;
		} else if (newIndex < minIndex) {
			newIndex = maxIndex;
		}

		setActiveImageIndex(newIndex);
	}

	let content;

	if (images.isLoaded && isActive) {
		content = (
			<div className="images-slider-wrap" id="images-slider-wrap">
				<div className="images-slider">
					<CloseButton
						customClass="close-images-slider"
						clickHandler={() => setSliderActive(false)}
					/>
					<MainImage
						images={images.images}
						activeImageIndex={activeImageIndex}
						changeActiveImageTo={changeActiveImageTo}
						prevActiveImages={prevActiveImages}
						fullscreenBreakpoint={fullscreenBreakpoint}
						fullScreenAvailable={fullScreenAvailable}
					/>
					<ImageThumbnails
						thumbnails={images.thumbnails}
						activeImageIndex={activeImageIndex}
						changeActiveImageTo={changeActiveImageTo}
					/>
				</div>
			</div>
		);
	} else {
		content = null;
	}

	return content;
}

export default ImagesSlider;