import { RenewReservation } from '../services/AccommodationBookingService';
import cookieHelper from '../helpers/CookieHelper';

export default {
	initialiseLocalStorage: (bookingDetails) => {
		const selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");
		localStorage.clear();
		localStorage.setItem("CurrencyCode", bookingDetails.currencyCode);
		localStorage.setItem("AccommodationBookingResource", bookingDetails.accommodationBookingResource);
		localStorage.setItem("BookingRef", bookingDetails.bookingRef);
		localStorage.setItem("Environment", bookingDetails.environment);
		localStorage.setItem("Guests", bookingDetails.guests);
		localStorage.setItem("Passengers", bookingDetails.passengers);
		localStorage.setItem("ReservationLink", bookingDetails.reservationLink);
		localStorage.setItem("ECommerceBookingLink", bookingDetails.eCommerceBookingLink);
		localStorage.setItem("InvoiceResource", bookingDetails.invoiceResource);
		localStorage.setItem("ECommerceToken", bookingDetails.eCommerceToken);
		localStorage.setItem("ECommerceTokenExpiryDate", bookingDetails.eCommerceTokenExpiryDate);
		localStorage.setItem("CommitStartResource", bookingDetails.commitStartResource);
		localStorage.setItem("AnalyticsId", bookingDetails.analyticsId);
		localStorage.setItem("InventoryResource", bookingDetails.inventoryResource);
		localStorage.setItem("Duration", bookingDetails.duration);
		localStorage.setItem("DepartureDate", bookingDetails.departureDate);
		localStorage.setItem("ReturnDateForTransfers", bookingDetails.returnDateForTransfers);
		localStorage.setItem("DocumentLink", bookingDetails.documentsLink);
		localStorage.setItem("HistoryLink", bookingDetails.historyLink);
		localStorage.setItem("SupportedCurrencies", JSON.stringify(bookingDetails.supportedCurrencies));
		localStorage.setItem("DepositAmount", bookingDetails.depositAmount);
		localStorage.setItem("DepositAmountInEuro", bookingDetails.depositAmountInEuro);
		localStorage.setItem("DepositAmountInUSD", bookingDetails.depositAmountInUSD);
		localStorage.setItem("BalanceDueDate", bookingDetails.balanceDueDate);
		localStorage.setItem("CentreResource", bookingDetails.centreResource);
		localStorage.setItem("TransportHubs", bookingDetails.transportHubs);
		localStorage.setItem("NonRefundableTermsLink", bookingDetails.nonRefundableTermsLink);
		localStorage.setItem("SavingsInGBP", bookingDetails.savingsInGBP);
		localStorage.setItem("SavingsInEUR", bookingDetails.savingsInEUR);
		localStorage.setItem("SavingsInUSD", bookingDetails.savingsInUSD);
		localStorage.setItem("VillaTotalCost", bookingDetails.villaTotalCost);
		localStorage.setItem("isAdHocBooking", bookingDetails.isAdHocBooking);

		cookieHelper.setStrictCookie("SelectedLanguageOption", selectedLanguage ? selectedLanguage : bookingDetails.selectedLanguage, 365);
	},
	reservationRenewal: (setGlobalErrorMessage, setIsLoaded) => {
		RenewReservation(setGlobalErrorMessage)
			.catch(error => {
				const message = error.response.data;
				setGlobalErrorMessage(message);
			})
			.finally(() => {
				setIsLoaded(true);
			})
	}
}
