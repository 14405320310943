import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { GetHealthAndSafety } from '../services/LegalitiesService';

const HealthAndSafety = (props) => {
	document.title = props.tabTitle;
	const [prismicContent, setPrismicContent] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		GetHealthAndSafety().then((response) => {
			setPrismicContent(response.data);
			setIsLoaded(true);

		}).catch(() => {
			setIsLoaded(true);
		})
	}, []);

	let content = null;

	if (!prismicContent && isLoaded)
		content = "Something went wrong. Please try again later.";
	else if (!isLoaded)
		content = (<div className="mb-5"><Skeleton height={300} /></div>)
	else if (prismicContent && isLoaded) {
		content = (
			<>
				<div className="card">
					<div className="card-body">
						<div className="pb-4">
								{prismicContent.description}
						</div>
						{prismicContent.sections.map((x, index) => {
							return (
								<>
									<div key={index}>
										<div dangerouslySetInnerHTML={{ __html: x.code }}></div>
									</div>
									<div className="pb-4" key={index}>
										<div dangerouslySetInnerHTML={{ __html: x.description }}></div>
									</div>
								</>
							)
						})}
					</div>
				</div>
			</>
		)
	}


	return (
		<div className="content-container">
			{content}
		</div>
	)
}

export default HealthAndSafety;