import axios from 'axios';
import cookieHelper from '../helpers/CookieHelper';

async function GetBookingSummary() {
	let accommodationBookingResource = localStorage.getItem("AccommodationBookingResource");

	return await axios.get(
		"/api/summary/GetSummary?accommodationBookingResource=" +
		encodeURIComponent(accommodationBookingResource));
}

async function GetBookingInvoice() {
	let token = localStorage.getItem("ECommerceToken");
	let currencyCode = localStorage.getItem("CurrencyCode");
	let invoiceResource = localStorage.getItem("InvoiceResource");

	return await axios.get("/api/summary/GetInvoice?invoiceResource=" + encodeURIComponent(invoiceResource) + "&currencyCode=" + currencyCode,
        { 'headers': { 'token': token } }
	);
}

async function GetInventoryDetails(inventoryResource) {
	return await axios.get(
		"/api/summary/GetInventoryDetails?inventoryResource=" +
		encodeURIComponent(inventoryResource));
}

async function GetAccommodationImages(imagesResource) {
	return await axios.get(
		"/api/summary/GetAccommodationImages?imagesResource=" +
		encodeURIComponent(imagesResource));
}

async function GetAdwDescription() {
	let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

	if (selectedLanguage == "en-US")
		selectedLanguage = "en";

	return await axios.get("/api/summary/GetADWDescription?language=" + selectedLanguage);
}

export { GetBookingSummary, GetBookingInvoice, GetInventoryDetails, GetAccommodationImages, GetAdwDescription };