import React from 'react';
import './checkoutMobileHeading.css';

const CheckoutMobileHeading = (props) => {
	const { summaryData } = props;

	return (
		<div className="checkout-mobile-heading">
			<div className="cmh-image-wrap">
				<img className="cmh-image" alt="Villa" src={summaryData.mainImage} />
			</div>
		</div>
	);
}

export default CheckoutMobileHeading;