import axios from 'axios';

async function CreateVoucherResource(voucherCode) {
	let token = localStorage.getItem("ECommerceToken");

	return await axios.post("/api/Voucher/AddVoucher",
		{
			eCommerceResource: localStorage.getItem("ECommerceBookingLink"),
			bookingResource: localStorage.getItem("AccommodationBookingResource"),
			voucherCode: voucherCode
		},
		{ 'headers': { 'token': token } }
	);

}

async function RemoveVoucherResource() {
	return await axios.post("/api/Voucher/RemoveVoucher", {
		eCommerceResource: localStorage.getItem("ECommerceBookingLink"),
		token: localStorage.getItem("ECommerceToken")
	});
}

export { CreateVoucherResource, RemoveVoucherResource };