import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

let aiInstrumentationKey = "";
if (window.location.host === "book.villaplustest.com") {
    aiInstrumentationKey = "6e9da288-ae63-4906-ac1d-61d1b0044253";
} else if (window.location.host === "book.villaplus.com") {
    aiInstrumentationKey = "6d3c02b1-1df9-4cd0-8b60-de62183c6252";
}

const ai = new ApplicationInsights({
    config: {
        instrumentationKey: aiInstrumentationKey,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

if (aiInstrumentationKey) {
    ai.loadAppInsights();
}

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;