import axios from 'axios';

async function GetLead() {
	let token = localStorage.getItem("ECommerceToken");
	let eCommerceResource = localStorage.getItem("ECommerceBookingLink");

	return await axios.get("/api/lead/GetLead?eCommerceResource=" + encodeURIComponent(eCommerceResource),
		{ 'headers': { 'token': token } }
	);
}

async function SaveLead(lead) {	
	let token = localStorage.getItem("ECommerceToken");
	let guests = localStorage.getItem("Guests");
	let accommodationBookingResource = localStorage.getItem("AccommodationBookingResource");
	let invoiceResource = localStorage.getItem("InvoiceResource");
	let currencyCode = localStorage.getItem("CurrencyCode");

	return await axios.post("/api/lead/SaveLead", { lead: lead, guests: JSON.parse(guests), accommodationBookingResource: accommodationBookingResource, currencyCode: currencyCode, invoiceResource: invoiceResource },
		{ 'headers': { 'token': token } }
	);
}

export { GetLead, SaveLead };