import react, { useState,  } from 'react';
import './localisationSelector.css';
import { dispatch } from 'use-bus';
import cookieHelper from '../../helpers/CookieHelper';
import { useLocation } from 'react-router-dom'
import useBus from 'use-bus';
import GlobeIcon from '../../images/globe-icon.svg';

function LocalisationSelector() {
	const location = useLocation();
	let language = cookieHelper.getCookie("SelectedLanguageOption") ?? "en";

	switch (language) {
		case "en":
			language = "UK";
			break;
		case "en-US":
			language = "US";
			break;
		default:
		language = language
	}

	const [selectedCurrency, setSelectedCurrency] = useState(localStorage.getItem("CurrencyCode") ?? "GBP");
	const [showLocalisationModal, setShowLocalisationModal] = useState(true);

	const currencySymbols = [{ name: "GBP", symbol: "£" }, { name: "USD", symbol: "$" }, { name: "EUR", symbol: "€" }];
	let selectedCurrencySymbol = currencySymbols.find((x) => { return x.name === selectedCurrency });
	let currencySymbol = selectedCurrencySymbol ? selectedCurrencySymbol.symbol: "£";

	function onLocalisationSelectorClick() {
		if (showLocalisationModal)
			dispatch("openLocalisationModal");
	}

	useBus("disableLocalisationSelector", () => {
		setShowLocalisationModal(false);
	})

	useBus("enableLocalisationSelector", () => {
		setShowLocalisationModal(true);
	})

	useBus("updateCurrencySymbol", () => {
		let currency = localStorage.getItem("CurrencyCode") ?? "GBP";

		setSelectedCurrency(currency);
		if (location.pathname === "/payment") {
			dispatch("onPaymentCurrencyChanged");
		}
		else {
			dispatch("onCurrencyChanged");
		}
	})

	return (
		<div className="text-nowrap rounded-pill mr-md-3">
			<div className="localisation-dropdown">
				<div className="pointer d-flex justify-content-center align-items-center font-weight-bold" onClick={() => { onLocalisationSelectorClick() }}>
					<span className="mr-1 d-flex justify-content-center align-items-center">
						<img src={GlobeIcon} className="pr-1" />
						<span className="font-size-15">
							{language.toUpperCase()} 
						</span>
					</span>
					<span dangerouslySetInnerHTML={{ __html: '&middot;' }}></span>

					<span className="font-size-15 ml-1">{currencySymbol}</span>
				</div>
			</div>
		</div>
	)
}
export default LocalisationSelector;