const regularExpressions = {
	UK: /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
	Netherland: /(NL-)?(\d{4})\s*([A-Z]{2})/
}

export default {
	ExtractPostcode(value) {
		let postcode;
		for (var country in regularExpressions) {
			postcode = value.match(regularExpressions[country]);
			if (postcode)
				break;
		}
		return postcode;
	},
	GetAddressWithoutPostcode(address, postcode) {
		let addressWithoutPostcode = address.replace(postcode, "");
		return addressWithoutPostcode.replace(/^[,\s]+|[,\s]+$/g, '').replace(/,[,\s]*,/g, ',');		
	}
}