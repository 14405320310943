import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { GetTermsOfUse } from '../services/LegalitiesService';

const TermsOfUse = (props) => {
	document.title = props.tabTitle;
	const [prismicContent, setPrismicContent] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		GetTermsOfUse().then((response) => {
			setPrismicContent(response.data);
			setIsLoaded(true);

		}).catch(() => {
			setIsLoaded(true);
		})
	}, []);

	let content = null;

	if ((!prismicContent || !prismicContent.sections) && isLoaded)
		content = "Something went wrong. Please try again later.";
	else if (!isLoaded)
		content = (<div className="mb-5"><Skeleton height={300} /></div>)
	else if (prismicContent && prismicContent.sections && isLoaded) {
		content = (
			<>
				<div className="card">
					<div className="card-body">
						{prismicContent.sections.map((x, index) => {
							return (<span key={index} dangerouslySetInnerHTML={{ __html: x }}></span>)
						})}
					</div>
				</div>
			</>
		)
	}


	return (
		<div className="content-container">
					{content}
		</div>
	)
}

export default TermsOfUse;