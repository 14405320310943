import React, { useState, useEffect } from 'react';
import formatHelper from '../../helpers/FormatHelper';
import Skeleton from 'react-loading-skeleton';
import Spinner from '../../images/spinner.svg';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import InformationIcon from '../../images/information-icon.svg';

const PriceBreakdown = (props) => {
	const { invoice, adwDescription } = props;
	const accommodationExtrasToFilter = ["free starter pack", "starter pack", "cot", "high chair", "z-bed", "pool fence"];
	const extrasTranslationMapping = [{ type: "z-bed", code: "Z_BED" }, { type: "free starter pack", code: "FREE_STARTER_PACK" }, { type: "starter pack", code: "STARTER_PACK" }, { type: "cot", code: "COT" }, { type: "high chair", code: "HIGH_CHAIR" }, { type: "pool fence", code: "POOL_FENCE" }]
	const { t } = useTranslation();
	const [totalCost, setTotalCost] = useState("");

	useEffect(() => {
		if (invoice.data && invoice.data.totalCosts && invoice.data.totalCosts.length > 0) {
			const currencyCode = localStorage.getItem("CurrencyCode");
			
			const totalPriceInSelectedCurrency = invoice.data.totalCosts.filter(x => x.currencyCode === currencyCode);

			if (totalPriceInSelectedCurrency.length > 0) {
				setTotalCost(formatHelper.formatPrice(totalPriceInSelectedCurrency[0].amount));
			} else {
				setTotalCost("");
			}
		} else {
			setTotalCost("");
		}
	}, [invoice]);

	let content;
	let nonGroupedItems = [];
	let accommodationExtras = [];
	let accommodationExtrasTotal = 0;
	let nonRefundableTermsLink = localStorage.getItem("NonRefundableTermsLink");

	//let transferBookings = [];

	if (invoice.error) {
		content = <i>Unable to load price breakdown</i>
	} else {
		if (invoice.isLoaded && invoice.data.costs) {
			invoice.data.costs.forEach((cost) => {
				if (cost.type.toLowerCase() === "accommodation.booking"
					&& accommodationExtrasToFilter.includes(cost.key.toLowerCase())) {
					let existingExtra = accommodationExtras.find((extra) => {
						return extra.description === cost.key
					});

					if (existingExtra != null) {
						existingExtra.totalAmount = existingExtra.totalAmount + cost.totalAmount;
						existingExtra.count++;
					} else {
						let translationMapping = extrasTranslationMapping.find((extra) => { return cost.key.toLowerCase() === extra.type.toLowerCase() });
						accommodationExtras.push({ description: cost.description, count: 1, totalAmount: cost.totalAmount, key: cost.key, translationKey: translationMapping ? translationMapping.code : null });
					}

					accommodationExtrasTotal = accommodationExtrasTotal + cost.totalAmount;
				} else if (cost.type.toLowerCase() === "transfers.booking") {
					var transOnInvoice = JSON.parse(sessionStorage.getItem("BookedTransfers"));
					if (transOnInvoice && transOnInvoice.length > 0) {
						var transfer = transOnInvoice.filter((x) => {
							return x.bookingLink === cost.resource
						});
						if (transfer && transfer.length > 0) {
							cost.description = cost.description.includes(transfer[0].airportName) ? cost.description.replace('Private transfer', t("PRIVATE_TRANSFERS_CARD_TITLE")).replace('people', t('PEOPLE')) : `${cost.description.replace('Private transfer', t("PRIVATE_TRANSFERS_CARD_TITLE")).replace('people', t('PEOPLE'))} / ${transfer[0].airportName}`;
							nonGroupedItems.push(cost);
						}
					}
				} else {
					if (cost.key.toLowerCase() === "villa price") {
						if (nonRefundableTermsLink != null && nonRefundableTermsLink != "") {
							cost.description = `Accommodation (${t("NON_REFUNDABLE")})`;
						}
						else {
							cost.description = t("ACCOMMODATION");
                        }
					}
					if (cost.key.toLowerCase() === "adw margin") {
						cost.extraInfo = adwDescription;
						cost.description = t("ADW");
					}

					nonGroupedItems.push(cost);
				}
			});

			//if (transferBookings.length > 0) {
			//	const transfersTotal = transferBookings.map(item => item.totalAmount).reduce((prev, curr) => prev + curr, 0);
			//	nonGroupedItems.push({ description: `Private transfer x ${transferBookings.length}`, totalAmount: transfersTotal });
			//}

			localStorage.setItem("SelectedExtras", JSON.stringify(accommodationExtras));
		}
		content = (
			<>
				<h2 className="pb-total-price">
					{
						(invoice.isLoaded && t("BASKET_TOTAL") + ": ") || (invoice.isReloading ? null : <Skeleton />)
					}
					<span className="pb-total-price-number">
						{totalCost}
					</span>
				</h2>
				{
					invoice.isLoaded ? nonGroupedItems.map((cost, index) => {
						return <p key={index}>{cost.description} : {formatHelper.formatPrice(cost.totalAmount)} {cost.extraInfo ?
							<Popup
								position="top right"
								trigger={
									<img src={InformationIcon}
										alt="Information icon"
										className="ml-1"
									/>
								}
							>
								<div dangerouslySetInnerHTML={{ __html: cost.extraInfo }}></div>
							</Popup>
							: ""}
							
						</p>
					})
						:
						invoice.isReloading ?
							<img alt="Loading" src={Spinner} className="loading-spinner" />
							:
							<>
								<p><Skeleton /></p>
								<p><Skeleton /></p>
								<p><Skeleton /></p>
							</>


				}

				<div className={accommodationExtras.length === 0 ? 'd-none' : ''}>
					<p>{t("ACCOMMODATION_EXTRAS")} {accommodationExtrasTotal != '0' ? formatHelper.formatPrice(accommodationExtrasTotal) : t("FREE")}</p>
					<ul className="dot-list">
						{
							accommodationExtras.map((cost, index) => {
								return (
									<li key={index}>
										{cost.translationKey ? t(cost.translationKey) : cost.description} x {cost.count}: {
											cost.key.toLowerCase() !== "free starter pack" ?
												formatHelper.formatPrice(cost.totalAmount)
												: t("FREE")
										}
									</li>
								)
							})
						}
					</ul>
				</div>
			</>
		);
	}

	return (
		<div className={(!invoice.isLoaded && invoice.isReloading ? "d-flex justify-content-center" : '') + " price-breakdown summary-text-section summary-text-section-border"}>
			{content}
		</div>
	);
}

export default PriceBreakdown;