import React, { useEffect } from 'react';
import Lead from '../components/contactDetails/Lead';
import AnalyticsHelper from '../helpers/AnalyticsHelper';

const ContactDetails = (props) => {
	document.title = props.tabTitle;	

	useEffect(() => {
		AnalyticsHelper.TrackPageLoad("contact_details_load", "Booking Contact Details");
	}, [])

	return (
		<div>
			<Lead summaryData={props.summaryData} />
		</div>
	);
}

export default ContactDetails;