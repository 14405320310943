import InputsPlus from '../../../images/inputs-plus.svg';
import InputsMinus from '../../../images/inputs-minus.svg';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

export default function AccommodationExtraQuantityContro({ showQuantity, quantity, extra, decreaseQuantity, increaseQuantity, onToggle, isSelected, isLoading }) {

	const { t } = useTranslation();

	return (
		<>
			{ showQuantity && extra.type !== "FreeStarterPack" ?
				<div className="d-flex flex-row mx-1 font-size-19 mb-2">
					<button
						className="ab-quantity-selector"
						onClick={decreaseQuantity}
						disabled={isLoading || (quantity === extra.minRequiredCount || quantity === 0)}
					>
						<img alt="" src={InputsMinus} />
					</button>
					{isLoading ? 
						<div className="d-flex flex-row justify-content-between align-items-center">
							<Skeleton className="mx-1" count={1} style={{ marginTop: "9px", width: "18px", height: "18px" }} />
						</div>
					:
						<span className="ab-quantity">
							{quantity}
						</span>
					}
					<button
						className="ab-quantity-selector"
						onClick={() => { increaseQuantity() }}
						disabled={isLoading || quantity === extra.maxItemsAvailable}
					>
						<img alt="" src={InputsPlus} />
					</button>
				</div>
				:
				''
			}
			
			<button
				className={isSelected ? "btn mx-1 ab-added-btn" : "ab-add-btn"}
				onClick={onToggle}
				disabled={isSelected && extra.type === "FreeStarterPack"}
			>
				{/*{isSelected ? t("ADDED") : t("VOUCHER_ADD_CONTROL")}	*/}
				{isSelected ? "Selected" : t("VOUCHER_ADD_CONTROL")}
			</button>
		</>
	)
}

