import PageTitle from '../shared/PageTitle';
import { useTranslation } from 'react-i18next';

const CarHireTransferNote = () => {
	const { t } = useTranslation();
	const centreName = localStorage.getItem("CentreName");

	let hideStarterPack = centreName == "Languedoc" || centreName == "Provence" || centreName == "French Riviera";

		return (
			<div className="information">
				<PageTitle title={t("EXTRAS_DISCLAIMER_TITLE").toLowerCase()} />
				<div id="disclaimerNoTransfers">
					<p>{t("EXTRAS_DISCLAIMER_INTRO")}</p>
					<ul>
						<li>{t("EXTRAS_DISCLAIMER_INSURANCE")}</li>
						<li>{t("EXTRAS_DISCLAIMER_CARHIRE")}</li>
						<li>{t("EXTRAS_DISCLAIMER_TRANSFERS")}</li>
						{!hideStarterPack &&
							<li>{t("EXTRAS_DISCLAIMER_STARTER_PACK")}</li>
						}
					</ul>
				</div>
			</div>
		);
	
}

export default CarHireTransferNote;