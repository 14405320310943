import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import formatHelper from "../../helpers/FormatHelper";
import basketIcon from '../../images/basket-icon.svg';
import basketIconNoBorder from '../../images/basket-icon-no-border.svg';
import buttonBack from '../../images/button-back.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { appInsights } from '../../services/AppInsights';
import useABTest from '../../hooks/useABTest';

const MobileSummary = (props) => {
	const { setActive, inventory, summary, invoice } = props;
	const [totalCost, setTotalCost] = useState("");
	const { t } = useTranslation();

	let history = useHistory();


	useEffect(() => {
		if (invoice.data && invoice.data.totalCosts && invoice.data.totalCosts.length > 0) {
			const currencyCode = localStorage.getItem("CurrencyCode");
			const totalPriceInSelectedCurrency = invoice.data.totalCosts.filter(x => x.currencyCode === currencyCode);

			if (totalPriceInSelectedCurrency.length > 0) {
				setTotalCost(formatHelper.formatPrice(totalPriceInSelectedCurrency[0].amount));
			} else {
				setTotalCost("");
			}
		} else {
			setTotalCost("");
		}
	}, [invoice]);

	function handleBackClick() {
		appInsights.trackEvent({
			name: 'Navigation',
			properties: {
				way: 'Back',
				fromPage: window.location.pathname,
				toPage: ''
			}
		});

		history.goBack();
	}

	let content;

	if (summary.error || inventory.error) {
		content = <i>Unable to load booking details</i>
	} else {
		content = (
			<>
				<img alt="" src={buttonBack} className="col-auto p-0" style={{ alignSelf: "self-start" }} onClick={handleBackClick} />
				<div className="ms-col-l col">
					<div className="ms-villa-name pb-1">
						{
							(inventory.isLoaded && "Villa " + inventory.data.villaName)
							|| <Skeleton />
						}
					</div>
					<div className="ms-dates">
						{
							(summary.isLoaded && summary.data && formatHelper.formatDate(summary.data?.startDate))
							|| <Skeleton />
						}
					</div>
					
				</div>
				<div className="ms-col-r d-flex justify-content-center align-items-center summary-button-mobile col-auto" onClick={setActive}>
					<img alt="" className="summary-button-mobile-image" src={basketIconNoBorder} />
					<div className="ms-villa-name summary-button-mobile-text">
						{
							(summary.isLoaded && inventory.isLoaded && t("SUMMARY"))
							|| <Skeleton />
						}
					</div>
				</div>
			</>
		);
	}

	return (
		<div className="mobile-summary px-1 py-2 my-1 d-md-none">
			{content}
		</div>
	);
}

export default MobileSummary;