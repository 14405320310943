import React, { useEffect } from "react";
import FormatHelper from '../../helpers/FormatHelper';
import CloseButton from "../../images/close-button.svg";
import { useTranslation } from 'react-i18next';

const ConfirmationPriceBreakdown = (props) => {
	const { costLines, setShowPriceBreakdown, totalCost } = props;
	const { t } = useTranslation();

	let nonRefundableTermsLink = localStorage.getItem("NonRefundableTermsLink");

	useEffect(() => {
		document.getElementById("cpd-wrap").addEventListener("click", function (e) {
			if (e.target.id === "cpd-wrap") {
				setShowPriceBreakdown(false);
			}
		});
	}, []);

	return (
		<div id="cpd-wrap" className="confirmation-price-breakdown-wrap">
			<div className="confirmation-price-breakdown card">
				<img alt="Close" className="cpb-close" src={CloseButton} onClick={() => setShowPriceBreakdown(false)} />
				<div className="card-body pt-4">
					{costLines.map((costLine, index) => {
						return (
							<div className="bc-pb-row pt-1" key={index}>
								<div className="bc-pb-title">
									{costLine.description}
									{(costLine.description == t("ACCOMMODATION") && (nonRefundableTermsLink != undefined && nonRefundableTermsLink != null && nonRefundableTermsLink != "")) &&
										` (${t("NON_REFUNDABLE")})`
									}
								</div> 
								<div className="bc-pb-price">{FormatHelper.formatPrice(costLine.amount)}</div>
							</div>
						)
					})}
					<hr />
					<div className="bc-pb-row">
						<div className="bc-total-price">
							<div className="bc-tp-title">{t("TOTAL_PRICE")}</div>
						</div>
						<div className="bc-pb-price">{FormatHelper.formatPrice(totalCost)}</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ConfirmationPriceBreakdown;
