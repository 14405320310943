import React from 'react';
import formatHelper from '../../../helpers/FormatHelper';
import paymentHelper from '../../../helpers/PaymentHelper';
import PaymentOption from './PaymentOption';
import Voucher from './Voucher'
import { useTranslation } from 'react-i18next';

const CostDetails = (props) => {
	const {
		setErrorMessage,
		setSuccessMessage,
		startCheckout,
		checkoutResponse,
		paymentOptionClickHandler,
		selectedPaymentOption,
		isSdkLoaded,
	} = props;

	const { t } = useTranslation();

	const sortedPaymentOptions = paymentHelper.sortPaymentOptionsByCost(checkoutResponse.paymentOptions);

	const currencyCode = localStorage.getItem("CurrencyCode");
	const nonRefundableTermsLink = localStorage.getItem("NonRefundableTermsLink");
	const isAdHocBooking = localStorage.getItem("isAdHocBooking") === "true";
	const discountedAmount = paymentHelper.getDiscountedAmount(checkoutResponse.paymentOptions);
	return (
		<div className="cost-details">
			<div className="row align-items-center cost-details-heading">
				<div className="col-4 col-sm-6 col-md-4 col-lg-6">
					<p className="card-title font-semibold m-0 d-block">{t("TOTAL_TO_PAY").toLowerCase()}</p>
					{
						checkoutResponse.invoice.totalCosts[0].amount > 0 &&
						<div className="payment-total">
								<div className={discountedAmount != null && discountedAmount > 0 ? 'payment-discounted-price-available payment-total-price' : 'payment-total-price'}>
									{discountedAmount != null && discountedAmount > 0 && <span className="payment-total-price-strike-through"></span>}
									<span>{checkoutResponse.invoice && checkoutResponse.invoice.totalCosts[0].amount && formatHelper.formatPrice(checkoutResponse.invoice.totalCosts[0].amount)}</span>
							</div>
							{discountedAmount != null && discountedAmount > 0 && <div className="payment-total-price">
								{formatHelper.formatPrice(discountedAmount)}
							</div>}
							<div className="payment-total-mobile d-none">{t("BASKET_TOTAL")}</div>
						</div>
					}
				</div>
				<div className="col-8 col-sm-6 col-md-8 col-lg-6">
					{currencyCode === "GBP" && (nonRefundableTermsLink == undefined || nonRefundableTermsLink == null || nonRefundableTermsLink == "") && !isAdHocBooking && <Voucher
						setErrorMessage={setErrorMessage}
						setSuccessMessage={setSuccessMessage}
						startCheckout={startCheckout}
					/>}
				</div>
			</div>
			{
				isSdkLoaded && checkoutResponse.paymentOptions && checkoutResponse.paymentOptions.length > 1 &&
				<div className="row">
					{sortedPaymentOptions &&
						sortedPaymentOptions
							.map((value, index) => {
								return (
									<PaymentOption
										key={index}
										selectedPaymentOption={selectedPaymentOption}
										paymentOptionClickHandler={paymentOptionClickHandler}
										paymentOption={value}
										balanceDueDate={checkoutResponse.invoice.balanceDueDate}
									/>
								);
							})}
				</div>
			}
		</div>
	)

}

export default CostDetails;