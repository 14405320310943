import axios from 'axios';

async function SaveDataInDotDigital(lead, eCommerceResource, summaryData) {
	await SaveEmailInDotDigital(lead.email, eCommerceResource);
	SaveLeadInDotDigital(lead, eCommerceResource);
	SaveSummaryDataInDotDigital(summaryData, lead.email, eCommerceResource);
}

async function SaveLeadInDotDigital(lead, eCommerceResource) {
	const bookingRef = localStorage.getItem("BookingRef");

	let leadInformationData = {
		firstName: lead.firstName,
		lastName: lead.lastName,
		emailAddress: lead.email,
		mobilePhoneNumber: lead.phoneNumber,
		address: lead.address,
		bookingRef: bookingRef
	};

	return await axios.post("/api/DotDigital/PersistLeadInformation",
		{ eCommerceResource: eCommerceResource, leadGuestEmail: lead.email, leadInformationData: leadInformationData }
	);
}

async function SaveEmailInDotDigital(emailid, eCommerceResource) {
	return await axios.post("/api/DotDigital/PersistContact?email=" + emailid + "&eCommerceResource=" + eCommerceResource);
}

async function SaveSummaryDataInDotDigital(summaryData, emailid, eCommerceResource) {
	let summary = {
		arrivalDate: summaryData.startDate,
		centre: summaryData.centre,
		resortName: summaryData.resortName,
		villaName: summaryData.villaName,
		nights: summaryData.nights,
		adults: summaryData.adults,
		children: summaryData.children,
		infants: summaryData.infants,
		bedrooms: summaryData.bedrooms,
		holidayType: summaryData.holidayType
	};

	return await axios.post("/api/DotDigital/PersistBookingSummary",
		{ eCommerceResource: eCommerceResource, leadGuestEmail: emailid, summaryData: summary }
	);
}

export { SaveDataInDotDigital };