import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import TranslationsDutch from '../jsonData/Translations/common.nl.json';
import TranslationsEnglish from '../jsonData/Translations/common.en.json';
import TranslationsGerman from '../jsonData/Translations/common.de.json';
import TranslationsSpanish from '../jsonData/Translations/common.es.json';
import TranslationsFrench from '../jsonData/Translations/common.fr.json';
import TranslationsPortuguese from '../jsonData/Translations/common.pt.json';
import TranslationsItalian from '../jsonData/Translations/common.it.json';
import TranslationsCroatian from '../jsonData/Translations/common.hr.json';
import TranslationsDanish from '../jsonData/Translations/common.da.json';
import TranslationsPolish from '../jsonData/Translations/common.pl.json';
import TranslationsLithuanian from '../jsonData/Translations/common.lt.json';
import TranslationsFinnish from '../jsonData/Translations/common.fi.json';
import TranslationsEstonian from '../jsonData/Translations/common.et.json';
import TranslationsNorwegian from '../jsonData/Translations/common.nb.json';
import TranslationsHungarian from '../jsonData/Translations/common.hu.json'; 
import TranslationsGreek from '../jsonData/Translations/common.el.json';
import TranslationsBulgarian from '../jsonData/Translations/common.bg.json';
import TranslationsCzech from '../jsonData/Translations/common.cs.json';
import TranslationsRomanian from '../jsonData/Translations/common.ro.json';
import TranslationsSwedish from '../jsonData/Translations/common.sv.json';
import TranslationsSerbian from '../jsonData/Translations/common.sr.json';
import TranslationsSlovak from '../jsonData/Translations/common.sk.json';
import TranslationsLatvian from '../jsonData/Translations/common.lv.json';
import TranslationsMaltese from '../jsonData/Translations/common.mt.json';
import TranslationsSlovenian from '../jsonData/Translations/common.sl.json';
import cookieHelper from '../helpers/CookieHelper';

let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

if (!selectedLanguage)
    selectedLanguage = "en";

const resources = {
	en: {
		translation: TranslationsEnglish
	},
	nl: {
		translation: TranslationsDutch
    },
    de: {
        translation: TranslationsGerman
    },
    es: {
        translation: TranslationsSpanish
    },
    fr: {
        translation: TranslationsFrench
    },
    pt: {
        translation: TranslationsPortuguese
    },
    it: {
        translation: TranslationsItalian
    },
    hr: {
        translation: TranslationsCroatian
    },
    da: {
        translation: TranslationsDanish
    },
    pl: {
        translation: TranslationsPolish
    },
    lt: {
        translation: TranslationsLithuanian
    },
    fi: {
        translation: TranslationsFinnish
    },
    et: {
        translation: TranslationsEstonian
    },
    nb: {
        translation: TranslationsNorwegian
    },
    hu: {
        translation: TranslationsHungarian
    },
    el: {
        translation: TranslationsGreek
    },
    bg: {
        translation: TranslationsBulgarian
    },
    cs: {
        translation: TranslationsCzech
    },
    ro: {
        translation: TranslationsRomanian
    },
    sv: {
        translation: TranslationsSwedish
    },
    sr: {
        translation: TranslationsSerbian
    },
    sk: {
        translation: TranslationsSlovak
    },
    lv: {
        translation: TranslationsLatvian
    },
    mt: {
        translation: TranslationsMaltese
    },
    sl: {
        translation: TranslationsSlovenian
    }
}
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: selectedLanguage,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
