import React, { useState, useEffect } from 'react';
import useBus from 'use-bus';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VillaPlusLogo from '../../images/villapluslogo.svg';
import BreadcrumbTrail from './BreadcrumbTrail';
import ABTA from '../../images/abta.svg';
import './header.css';
import LocalisationSelector from './LocalisationSelector';
import cookieHelper from '../../helpers/CookieHelper';

function Header(props) {
	const [activePage, setActivePage] = useState(false);
	const [title, setTitle] = useState(null);
	const [displayExtras, setDisplayExtras] = useState(true);
	const [displayTransfers, setDisplayTransfers] = useState(true);

	let location = useLocation();
	const titles = [{ pathName: "/", title: "EXTRAS_HEADER_TITLE" }, { pathName: "/extras", title: "EXTRAS_HEADER_TITLE" },
		{ pathName: "/contactdetails", title: "CONTACT_DETAILS_HEADER_TITLE" }, { pathName: "/payment", title: "PAYMENT_HEADER_TITLE" },
		{ pathName: "/confirmation", title: "CONFIRMATION_HEADER_TITLE" }, { pathName: "/booking-confirmation", title: "THANK_YOU_NAME" },
		{ pathName: "/booking-conditions", title: "BOOKING_CONDITIONS_HEADER_TITLE" },
		{ pathName: "/alternativevilla", title: "ALTERNATIVE_VILLA_HEADER_TITLE" }, { pathName: "/late-booking", title: "LATE_BOOKING_HEADER_TITLE"}, { pathName: "/paymentcompleted", title: "PAYMENT_COMPLETED_HEADER_TITLE" },
	{ pathName: "/website-terms-of-use", title: "WEBSITE_TERMS_OF_USE" }, { pathName: "/health-and-safety", title: "HEALTH_AND_SAFETY_HEADER_TITLE" }];

	const { t } = useTranslation();

	const language = cookieHelper.getCookie("SelectedLanguageOption");
	const isUKSpecific = (language === null || language === "en");

	useBus("useConfirmationPageTitle", () => {
		setTitle(t("THANK_YOU_NAME") + ", " + localStorage.getItem("LeadFirstName"));
	});

	useBus("noExtras", () => {
		setTimeout(() => {
			setDisplayExtras(false);
		}, 100);
	});
	useBus("noTransfers", () => {
		setTimeout(() => {
			setDisplayTransfers(false);
		}, 100);
	});

	useEffect(
		() => {
			if (location != null) {
				let pageInformation = titles.find((x) => { return x.pathName.toLowerCase() === location.pathname });
				if (pageInformation != null) {
					if (pageInformation.pathName !== "/booking-confirmation") {
						setTitle(pageInformation.title);
					}
				}
				setActivePage(location.pathname);
			}
		}, [location])

	return (
		<header className="px-2">
			<div className="d-flex headerRow justify-content-between align-items-center">
				<div className="header-logo-wrap">
					<img className="header-logo" src={VillaPlusLogo} alt="Villa Plus" />
				</div>
				<BreadcrumbTrail activePage={activePage} title={title} displayExtras={displayExtras || displayTransfers} />
				<div className="d-flex justify-content-between align-items-center">
					<LocalisationSelector />
					{isUKSpecific ?
						<>
							<a href="https://www.villaplus.com/faq" target="_blank" rel="noreferrer"
								className="d-none d-md-flex align-items-center h-faqs-link font-size-14"
							>
								<span className="font-semibold header-dark-blue">{t("FAQ")}</span>
								<div className="h-faqs-icon">?</div>
							</a>
							<img alt="ABTA" className="d-none d-md-block abta-logo" src={ABTA} />
						</>
						: null
					}

				</div>
			</div>
		</header>
	)
}

export default Header;