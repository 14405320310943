import axios from 'axios';
import cookieHelper from '../helpers/CookieHelper';

async function GetBookingConditions() {
	let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

	if (selectedLanguage == "en-US")
		selectedLanguage = "en";

	return await axios.get("Legalities/BookingConditions?language=" + selectedLanguage);
}

async function GetTermsOfUse() {
	let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

	if (selectedLanguage == "en-US")
		selectedLanguage = "en";

	return await axios.get("Legalities/TermsOfUse?language=" + selectedLanguage);
}

async function GetHealthAndSafety() {
	let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

	if (selectedLanguage == "en-US")
		selectedLanguage = "en";

	return await axios.get("Legalities/HealthAndSafety?language=" + selectedLanguage);
}

export { GetBookingConditions, GetTermsOfUse, GetHealthAndSafety };