import axios from 'axios';
import cookieHelper from '../helpers/CookieHelper';

async function RenewEcommerceToken(errorHandler) {
	const bookingRef = localStorage.getItem("BookingRef");
	const leadLastName = localStorage.getItem("LeadLastName") ?? "";
	
	return await axios.post("Booking/RenewEcommerceToken", {
		bookingRef: bookingRef,
		leadLastName: leadLastName
	})
		.catch(error => {
			const message = error.response.data;
			errorHandler(message);
		});
}
async function PersistSelectedLanguage() {
	let language = cookieHelper.getCookie("SelectedLanguageOption");
	let token = localStorage.getItem("ECommerceToken");
	let eCommerceResource = localStorage.getItem("ECommerceBookingLink");

	if (language == "en-US")
		language = "en";

	return await axios.post("Booking/PersistSelectedLanguage", { language: language, token: token, eCommerceResource: eCommerceResource });
}

export { RenewEcommerceToken, PersistSelectedLanguage }