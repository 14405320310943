import React, { useState, useEffect } from 'react';
import PageTitle from '../../shared/PageTitle';
import { dispatch } from 'use-bus'
import ErrorMessage from '../../shared/errorMessage/ErrorMessage';
import PrivateTransfersSlider from './PrivateTransfersSlider';
import AirportSelector from './AirportSelector';
import { useTranslation } from 'react-i18next';
import { GetTransfers } from '../../../services/TransfersService';
import NoTransfersForAirport from './NoTransfersForAirport';
import Spinner from '../../../images/spinner.svg';
import PrivateTransfersImage from '../../../images/PrivateTransfers.png';

const PrivateTransfers = ({ defaultTransfers }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isError, setIsError] = useState(false);
	const [transfers, setTransfers] = useState([]);

	const airports = JSON.parse(localStorage.getItem("TransportHubs"));
	const errorMessage = "Private Transfers could not be loaded at this time.";


	const { t } = useTranslation();
	const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";

	function onAirportChange(changedAirport) {
		if (!isPaymentCompleted && changedAirport) {
			GetAvailableTransfers(changedAirport.Code);
		}
	}

	useEffect(() => {
		setAvailableTransfer(defaultTransfers.transfers, defaultTransfers.airportCode)
	}, [])

	function GetAvailableTransfers(airportCode) {
		setIsLoaded(false);
		setTransfers([]);
		GetTransfers(airportCode)
			.then((response) => {
				if (response && response.data) {
					setAvailableTransfer(response.data, airportCode)
				} 
			})
			.catch(error => {
				setTransfers([]);
				setIsError(true);
				setIsLoaded(true);
			});
	}

	function setAvailableTransfer(privateTransfers, airportCode) {
		var transOnInvoice = JSON.parse(sessionStorage.getItem("BookedTransfers"));
		if (transOnInvoice && transOnInvoice.length > 0) {
			var currentTransfers = transOnInvoice.filter((x) => {
				return x.airportCode === airportCode
			});

			privateTransfers.forEach((transfer) => {
				currentTransfers.forEach((currentTransfer) => {
					if (currentTransfer.reserveLink == transfer.reserve) {
						transfer.bookingLink = currentTransfer.bookingLink;
						transfer.isChecked = true;
					}
				});
			});
		}
		setTransfers(privateTransfers);

		sessionStorage.setItem("TransfersAvailable", true);

		setIsError(false);
		setIsLoaded(true);
	}

	let content = (
		<div>
			<AirportSelector airports={airports} changeAirport={onAirportChange} defaultAirportCode={defaultTransfers.airportCode} />
			<div style={{ textAlign: 'center' }}>
				<img id="private-transfers-image" style={{ maxWidth: '299px', maxHeight: '182px', marginBottom: '8px' }} src={PrivateTransfersImage} className={transfers.length === 0 ? "d-none" : ""}></img>
			</div>
			{ ((isLoaded && transfers.length > 0) && (
				<div>
					<div id="private-transfers-slider-ab">
						<PrivateTransfersSlider transfers={transfers} />
					</div>
				</div>
				))
				||
					((isLoaded && transfers.length === 0) && <NoTransfersForAirport />)
				||
					<>
						<img alt="Loading" src={Spinner} className="d-flex mx-auto" style={{width: '15%'}} />
					</>
			}
		</div>
	);

	if (isLoaded && !isError && transfers.length === 0 && airports.length <= 1) {
		sessionStorage.setItem("TransfersAvailable", false);
		dispatch("noTransfers");
		dispatch("transfersSet");
		return <></>
	}
	else {
		return (
			<div>
				<div id="privateTransfersCard" className="card colored-bottom mb-3">
					<div className="card-body">
						{
							isError && <ErrorMessage message={errorMessage} />
						}
						<PageTitle title={t("PRIVATE_TRANSFERS_CARD_TITLE").toLowerCase()} informationText={t("PRIVATE_TRANSFERS_INFORMATION_TEXT")} informationLabel="Private Airport Transfer" />
						{content}
					</div>
				</div>
			</div>
		);
	}
}

export default PrivateTransfers;