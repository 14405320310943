import { appInsights } from '../services/AppInsights';

export default {
	logInvalidPaymentSDK() {
		appInsights.trackEvent({
			name: 'invalid-payment-sdk',
			properties: {
				duration: localStorage.getItem("Duration"),
				villaName: localStorage.getItem("VillaName"),
				departureDate: localStorage.getItem("DepartureDate")
			}
		});
	},
	logTokenRenewal() {
		const currentDate = new Date();

		appInsights.trackEvent({
			name: 'token-renewal',
			properties: {
				bookingRef: localStorage.getItem("BookingRef"),
				tokenRenewalDate: currentDate.toString(),
				oldTokenExpiryDate: localStorage.getItem("ECommerceTokenExpiryDate").toString(),
				newTokenExpiryDate: new Date(currentDate.setHours(currentDate.getHours() + 1)).toString()
			}
		});
	},
	logCommitStart() {
		const currentDate = new Date();

		appInsights.trackEvent({
			name: "commit-start",
			properties: {
				bookingRef: localStorage.getItem("BookingRef"),
				commitStartDate: currentDate.toString()
			}
		});
    }
}