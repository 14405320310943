import React from 'react';
import Skeleton from 'react-loading-skeleton';
import formatHelper from "../../helpers/FormatHelper";
import { useTranslation } from 'react-i18next';

const AccommodationInfo = (props) => {
	const { inventory, summary } = props;
	const { t } = useTranslation();

	let content;

	if (summary.error || inventory.error) {
		content = <i>Unable to load booking details</i>
	} else {
		content = (
			<>
				<h1 className="ai-villa-name">
					{
						(inventory.isLoaded && "Villa " + inventory.data.villaName)
						|| <Skeleton />
					}
				</h1>
				<div className="ai-villa-description pt-2">
					<p>
						{
							(inventory.isLoaded && inventory.data.resortName + ", " + inventory.data.centreName)
							|| <Skeleton />
						}
					</p>
					<p>
						{
							(summary.isLoaded && summary.data && formatHelper.formatDate(summary.data.startDate)
								+ " - " + formatHelper.formatDate(summary.data.returnDate))
							|| <Skeleton />
						}
					</p>
					<p>
						{
							(summary.isLoaded && summary.data && formatHelper.formatGuests(summary.data.guests, t("ADULTS"), t("CHILDREN"), t("INFANTS")))
							|| <Skeleton />
						}
					</p>
					<p>
						{
							(inventory.isLoaded &&
								(inventory.data.bedrooms + " " + (inventory.data.bedrooms === 1 ? t("BEDROOM") : t("BEDROOMS")) + "  ·  " +
								inventory.data.bathrooms + " " + (inventory.data.bathrooms === 1 ? t("BATHROOM") : t("BATHROOMS")))
							)
							|| <Skeleton />
						}
					</p>
				</div>
			</>
		);
	}

	return (
		<div className="accommodation-info summary-text-section mb-0">
			{content}
		</div>
	);
}

export default AccommodationInfo;