import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import ImagesSlider from '../imagesSlider/ImagesSlider';

const AccommodationImages = (props) => {
	const FULLSCREEN_WIDTH_BREAKPOINT = 600;

	const { images } = props;

	const [sliderActive, setSliderActive] = useState(false);
	const [fullScreenAvailable, setFullScreenAvailable] = useState(true);

	let villaName = localStorage.getItem("VillaName");
	let centreName = localStorage.getItem("CentreName");
	let resortName = localStorage.getItem("ResortName");

	let altTag = null;

	if (!villaName || !centreName || !resortName)
		altTag = "Villa Image";
	else
		altTag = "Villa " + villaName + ", " + resortName + ", " + centreName;

	useEffect(() => {
		const sliderElem = document.getElementById("images-slider-wrap");
		if (sliderActive) {
			document.body.style.overflowY = "hidden";
			if (window.innerWidth <= FULLSCREEN_WIDTH_BREAKPOINT) {
				if (sliderElem.requestFullScreen) {
					sliderElem.requestFullscreen();
				} else if (sliderElem.mozRequestFullScreen) {
					sliderElem.mozRequestFullScreen();
				} else if (sliderElem.webkitRequestFullScreen) {
					sliderElem.webkitRequestFullScreen();
				} else {
					setFullScreenAvailable(false);
					sliderElem.classList.add("no-fullscreen-slider");
				}
			}
		} else {
			document.body.style.overflowY = "auto";
			if (window.innerWidth <= FULLSCREEN_WIDTH_BREAKPOINT && document.fullscreenElement) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.mozExitFullscreen) {
					document.mozExitFullscreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else {
					setFullScreenAvailable(false);
					sliderElem.classList.remove("no-fullscreen-slider");
				}
			}
		}
	}, [sliderActive]);

	function toggleSliderActive() {
		setSliderActive(!sliderActive);
	}

	let content;

	if (images.error) {
		content = <i>Unable to load images</i>
	} else {
		content = (
			<>
				{images.isLoaded ?
					(images.thumbnails.slice(0, 2).map(i => {
						return <img className="thumbnail-image"
							key={i.orderIndex} src={i.url} alt={altTag}
							onClick={() => toggleSliderActive()}
						/>
					})) : (
						<>
							<div className="thumbnail-image">
								<Skeleton height={148} />
							</div>
							<div className="thumbnail-image">
								<Skeleton height={148} />
							</div>
						</>
					)
				}

				<ImagesSlider
					images={images}
					isActive={sliderActive}
					setSliderActive={setSliderActive}
					fullscreenBreakpoint={FULLSCREEN_WIDTH_BREAKPOINT}
					fullScreenAvailable={fullScreenAvailable}
				/>
			</>
		);
	}

	return (
		<div className="accommodation-images d-flex justify-content-between">
			{content}
		</div>
	);
}

export default AccommodationImages;