import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import InformationIcon from '../../../images/information-icon.svg';
import 'reactjs-popup/dist/index.css';
import './accommodationExtras.css';
import '../../shared/controls/checkboxInput.css';
import { useTranslation } from 'react-i18next';
import FormatHelper from '../../../helpers/FormatHelper';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import AccommodationExtraQuantityControl from "./AccommodationExtraQuantityControl"
import CloseIcon from '../../../images/button-bin-slim.svg'


const AccommodationExtra = (props) => {
	const { extra, index } = props;
	const [isSelected, setIsSelected] = useState(extra.selectedItemsCount > 0 || extra.type === "FreeStarterPack");
	const [quantity, setQuantity] = useState(extra.selectedItemsCount);
	const [isLoading, setIsLoading] = useState(false);
	const [showQuantity, setShowQuantity] = useState(extra.selectedItemsCount != null && extra.selectedItemsCount > 0 && extra.type !== "FreeStarterPack");
	const { t } = useTranslation();

	useEffect(() => {
		if (quantity === 0) {
			setShowQuantity(false);
			setIsSelected(false);
		}
	}, [quantity])



	function onClick() {
		if (quantity > 0 && quantity === extra.minRequiredCount)
			return;

		const wasSelected = !isSelected;
		setIsLoading(true);

		if (wasSelected) {
			props.addExtra(extra, 1).then(() => {
				resetForm(wasSelected);
			})
		}
		else {
			props.removeSelectedExtra(extra, extra.selectedItemsCount).then(() => {
				resetForm(wasSelected);
			})
		}
	}

	function resetForm(value) {
		setIsSelected(value);
		setQuantity(value ? 1 : 0);
		setIsLoading(false);
		setShowQuantity(value);
	}

	function decreaseQuantity() {
		if ((quantity - 1) < extra.minRequiredCount)
			return;

		setIsLoading(true);

		props.removeSelectedExtra(extra, 1).then((response) => {
			if (response) {
				setQuantity(quantity - 1);
			}
			setIsLoading(false);
			if (quantity - 1 === 0)
				setIsSelected(false);
		})
	}

	function increaseQuantity() {
		if ((quantity + 1) > extra.maxItemsAvailable)
			return;

		setIsLoading(true);

		props.addExtra(extra, 1).then((response) => {
			if (response) {
				setQuantity(quantity + 1);
			}

			setIsLoading(false);
		})
	}

	return (
		<div className="my-sm-4 my-3 col ab-extras-card-wrapper">
			<div className={`d-flex flex-column ab-extras-card ${isSelected ? "ab-extras-card-active" : ""}`}>
				<div className="d-flex flex-column">
					<div className="d-flex flex-row align-items-center">
						<span className="vertical-align-bottom ab-extras-card-title">{t(extra.translationKey ? extra.translationKey : extra.description)}</span>
						{extra.information != null ?
							<Popup
								trigger={
									<img
										src={InformationIcon}
										alt="Information icon"
										className="pointer"
										style={{ height: "18px", width: "18px", marginLeft: "6px", marginTop: "4px" }}
									/>
								}
								position="bottom left"
								onOpen={() => AnalyticsHelper.TrackInfoBox(extra.description)}
							>
								<div dangerouslySetInnerHTML={{ __html: extra.information }}></div>
							</Popup>
							:
							''
						}
						{isSelected && extra.type !== "FreeStarterPack" && <div onClick={onClick} className={`pointer ml-auto`} style={{ position: "relative", marginRight: "-20px", marginTop: "-25px" }}>
							<img src={CloseIcon} alt="" />
						</div>}
					</div>
					<div className="font-size-19">
						<div className="ab-extras-card-price">
							{ extra.type === "FreeStarterPack" ?
								t("FREE") :
								extra.selectedItemsCount <= 0 ?
									FormatHelper.formatPrice(extra.cost.amount) :
									FormatHelper.formatPrice(extra.cost.amount * extra.selectedItemsCount)
							}
						</div>
					</div>
				</div>

				<div className="mt-1 mb-2">
					<img src={extra.image} alt={extra.type} loading="lazy" className="ab-extras-card-image"/>
				</div>

				<div className="d-flex flex-wrap justify-content-center align-items-center">
						<AccommodationExtraQuantityControl
							showQuantity={showQuantity}
							onToggle={onClick}
							extra={extra}
							quantity={quantity}
							increaseQuantity={increaseQuantity}
							decreaseQuantity={decreaseQuantity}
							isSelected={isSelected}
							isLoading={isLoading}
						/>
					</div>
			</div> 
		</div>
	)
}

export default AccommodationExtra;