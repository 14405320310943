import { RenewEcommerceToken } from '../services/EcommerceService';
import AppInsightsHelper from '../helpers/AppInsightsHelper';

export default {
	checkAndRenewECommerceToken(setGlobalErrorMessage) {
		if (localStorage.getItem("BookingRef") === null) return;

		const tokenExpiryString = localStorage.getItem("ECommerceTokenExpiryDate");
		if (!tokenExpiryString) return;

		const tokenExpiryDate = new Date(tokenExpiryString);
		const dateTimeNow = new Date();
		const minutesUntilTokenExpires = Math.floor((tokenExpiryDate - dateTimeNow) / (1000 * 60));

		if (minutesUntilTokenExpires < 20) {
			RenewEcommerceToken(setGlobalErrorMessage)
				.then(response => {
					localStorage.setItem("ECommerceToken", response.data.access_token);
					localStorage.setItem("ECommerceTokenExpiryDate", response.data.tokenExpiryDate);
					AppInsightsHelper.logTokenRenewal();
				});
		}
	}
}

