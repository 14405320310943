import React from 'react';
import Spinner from '../../../images/spinner.svg';
import './fullScreenLoading.css';

const FullScreenLoading = (props) => {
	return (
		<div className="full-screen-loading">
			<img src={Spinner} className="fsl-image" alt="Loading..." />
		</div>
	);
}

export default FullScreenLoading;