export default {

	IsBlankValue(value) {
		return (value === "" || value === null) ? true : false;
	},

	IsInvalidMinLengthCriteria(value, min) {
		return (value.length < min) ? true : false;
	},

	IsInvalidMaxLengthCriteria(value, max) {
		return (value.length > max) ? true : false;
	},

	IsInvalidValue(value, type) {
		if (type === "number") {
			return (!value.match(/^[0-9]*$/i)) ? "ONLY_NUMBERS" : "";
		}

		if (type === "email") {
			return (!value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) ? "INVALID_EMAIL_ADDRESS" : "";
		}
		return "";
	}	
}