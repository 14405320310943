import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { GetBookingConditions } from '../services/LegalitiesService';

const BookingConditions = (props) => {
	document.title = props.tabTitle;
	const [prismicContent, setPrismicContent] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const environment = localStorage.getItem("Environment");
	const domain = environment.toLowerCase() === "production" ? "https://book.villaplus.com" : "https://book.villaplustest.com";

	useEffect(() => {
		GetBookingConditions().then((response) => {
			setPrismicContent(response.data);
			setIsLoaded(true);

		}).catch(() => {
			setIsLoaded(true);
		})
	}, []);

	let content = null;

	if (!prismicContent && isLoaded)
		content = "Something went wrong. Please try again later.";
	else if (!isLoaded)
		content = (<div className="mb-5"><Skeleton height={300} /></div>)
	else if (prismicContent && isLoaded) {
		content = (
			<>
				<div className="card">
					<div className="card-body">
						{prismicContent.mainSections.map((x, index) => {
							//HACKALERT: Once the legalities pages will be translated we can replace matching on the url to matching on the code similarly to footer links in the email.
							if (x.description.includes("https://www.villaplus.com/health-and-safety")) {
								var healthAndSafetyRegex = new RegExp("https://www.villaplus.com/health-and-safety", 'g');
								x.description = x.description.replace(healthAndSafetyRegex, (domain + "/health-and-safety"))
							}
							if (x.description.includes("https://www.villaplus.com/website-terms-of-use")) {
								var termsOfUseRegex = new RegExp("https://www.villaplus.com/website-terms-of-use", 'g');
								x.description = x.description.replace(termsOfUseRegex, (domain + "/website-terms-of-use"))
							}
							return (<span key={index} dangerouslySetInnerHTML={{ __html: x.description }}></span>)
						})}
					</div>
				</div>
			</>
		)
	}


	return (
		<div className="content-container">
			{content}
		</div>
	)
}

export default BookingConditions;