import React, { useState } from 'react';
import ArrowIcon from '../../images/left-arrow.svg';

const MainImage = (props) => {
	const {
		images,
		activeImageIndex,
		changeActiveImageTo,
		prevActiveImages,
		fullscreenBreakpoint,
		fullScreenAvailable
	} = props;

	const [touchXPosition, setTouchXPosition] = useState(null);
	const [touchYPosition, setTouchYPosition] = useState(null);

	const handleTouchStart = (e) => {
		setTouchXPosition(e.touches[0].clientX);
		setTouchYPosition(e.touches[0].clientY);
	}

	const handleTouchMove = (e) => {
		const touchDownX = touchXPosition;
		const touchDownY = touchYPosition;
		if (!touchDownX && !touchDownY) {
			return;
		}

		const currentTouchX = e.touches[0].clientX;
		const horizontalDiff = touchDownX - currentTouchX;

		const currentTouchY = e.touches[0].clientY;
		const verticalDiff = touchDownY - currentTouchY;

		let diff = 0;
		if (window.innerWidth > fullscreenBreakpoint || !fullScreenAvailable) {
			diff = horizontalDiff;
		} else {
			diff = verticalDiff;
		}

		if (diff > 5) {
			changeActiveImageTo(activeImageIndex + 1);
		}

		if (diff < -5) {
			changeActiveImageTo(activeImageIndex - 1);
		}

		setTouchXPosition(null);
		setTouchYPosition(null);
	}

	let content = (
		<div className="is-main-image-wrap">
			<div className="is-main-image" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
				{
					images.map(i => {
						const intOrderIndex = parseInt(i.orderIndex);
						const isActive = activeImageIndex === intOrderIndex;
						return (
							<img alt="Main"
								key={i.orderIndex}
								src={(isActive || prevActiveImages.includes(intOrderIndex)) ? i.url : "#"}
								className={"is-main-image-img" + (isActive ? " active" : "")}
							/>
						);
					})
				}
				<div className="is-arrow is-left-arrow" onClick={() => changeActiveImageTo(activeImageIndex - 1)}>
					<img alt="Left" className="is-arrow-icon" src={ArrowIcon} />
				</div>
				<div className="is-arrow is-right-arrow" onClick={() => changeActiveImageTo(activeImageIndex + 1)}>
					<img alt="Right" className="is-arrow-icon" src={ArrowIcon} />
				</div>
			</div>
		</div>
	);

	return content;
}

export default MainImage;