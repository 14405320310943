import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import InformationIcon from '../../images/information-icon.svg';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';

function PageTitle(props) {
	let { title, informationText, informationLabel } = props;
		return (
			<div className="card-title mb-2">
				{title}
				{informationText != null ?
					<Popup
						trigger={<img src={InformationIcon} alt="Information icon" className="ml-1" />}
						position="bottom left"
						onOpen={() => AnalyticsHelper.TrackInfoBox(informationLabel)}
					>
						<div dangerouslySetInnerHTML={{ __html: informationText }}></div>
					</Popup>
					:
					''
				}
			</div>
		);	
}

export default PageTitle;