import React, { useState, useEffect } from 'react';
import './textInput.css';
import ValidationHelper from '../../../helpers/ValidationHelper';
import { useTranslation } from 'react-i18next';

const TextInput = (props) => {

	let {
		placeholder,
		onChange,
		triggerValidation,
		showValidationMsg,
		value,
		type,
		minLength,
		maxLength,
		name,
		missingValueMessage,
		pattern
	} = props;
	const { t } = useTranslation();

	TextInput.defaultProps = {
		type: "text"
	}

	const [placeholderMsg, setPlaceholderMsg] = useState(placeholder);
	const [showValidation, setShowValidation] = useState(false);

	useEffect(() => {
		if (showValidationMsg) {
			checkValidation();
		}
	}, [triggerValidation]);

	function onFocus() {
		setShowValidation(false);
		setPlaceholderMsg(placeholder);
		if (name === "Address")
			props.handleAddressEvents(true);
	}

	function onBlur() {
		if (showValidationMsg) {
			checkValidation();
		}
		if (name === "Address")
			props.handleAddressEvents(false);
	}

	function checkValidation() {
		if (ValidationHelper.IsBlankValue(value.trim())) {
			setShowValidation(true);
			if (missingValueMessage)
				setPlaceholderMsg(missingValueMessage);
			else
				setPlaceholderMsg("Please enter your " + placeholder);
		}
		else if (ValidationHelper.IsInvalidValue(value, type) !== "") {
			setShowValidation(true);
			const result = ValidationHelper.IsInvalidValue(value, type);

			if (result)
				setPlaceholderMsg(t(result));
		}
		else if (minLength && ValidationHelper.IsInvalidMinLengthCriteria(value, minLength)) {
			setShowValidation(true);
			setPlaceholderMsg(t("SHORT_MOBILE_NUMBER").replace("{count}", minLength));
		}
		else if (maxLength && ValidationHelper.IsInvalidMaxLengthCriteria(value, maxLength)) {
			setShowValidation(true);
			setPlaceholderMsg(t("LONG_MOBILE_NUMBER").replace("{count}", maxLength));
		}

	}

	return (
		<>
			<input className={(showValidation) ? "input-field show-validation" : "input-field"} placeholder=" "
				onChange={onChange} onBlur={onBlur} onFocus={onFocus} value={value} type={type} pattern={pattern} />
			<span className={(showValidation) ? "placeholder-wrapper show-validation" : "placeholder-wrapper"}> {placeholderMsg}</span>
		</>
	)
}

export default TextInput;