import React from 'react';
import './PrimaryButton.css';

const Button = ({ onClick, buttonText }) => {
    return (
        <button className="w-100 primaryButton" onClick={onClick}>
            {buttonText}
        </button>
    );
};

export default Button;