import React, { useState, useEffect } from 'react';
import formatHelper from '../../../helpers/FormatHelper';
import RadioInput from '../../shared/controls/RadioInput';
import { useTranslation } from 'react-i18next';

const paymentOptions = {
	"deposit only": "DEPOSIT_ONLY_OPTION",
	"full amount": "FULL_AMOUNT_OPTION",
	"no payment": "NO_PAYMENT",
	"pay balance later": "PAY_BALANCE_LATER"
}

const PaymentOption = (props) => {
	const { selectedPaymentOption, paymentOptionClickHandler, paymentOption, balanceDueDate } = props;
	const [formattedDueDate, setFormattedDueDate] = useState("");
	const { t } = useTranslation();

	function selectPaymentOption() {
		sessionStorage.setItem("SelectedPaymentOption", JSON.stringify(paymentOption))
		paymentOptionClickHandler(paymentOption);
	}

	useEffect(() => {
		if (balanceDueDate) {
			const formattedDate = formatHelper.formatDate(balanceDueDate, { day: 'numeric', month: 'short', year: 'numeric' });
			setFormattedDueDate(formattedDate);
		}
	}, [balanceDueDate]);

	if (!selectedPaymentOption) {
		return <div></div>;
	} else {
		return (
			<div className="col-sm-6 col-md-12 col-lg-6">
				<RadioInput
					name="selectedPaymentGroup"
					clickHandler={selectPaymentOption}
					isSelected={selectedPaymentOption.displayName === paymentOption.displayName}
				>
					<span className="font-light">
						{t(paymentOptions[paymentOption.displayName.toLowerCase()])}
						{
							(paymentOption.paymentAmounts[0].paymentAmount > 0) &&
							<span className="ml-1 font-bold">
								{formatHelper.formatPrice(paymentOption.paymentAmounts[0].paymentAmount)}
							</span>
						}
						{
							(paymentOption.displayName.toLowerCase() === "deposit only") &&
							<>
								<br />
								<span>
									{t("DEPOSIT_ONLY_DISCLAIMER")} <span className="font-normal">{formattedDueDate}</span>
								</span>
							</>
						}
							
					</span>
				</RadioInput>
			</div>
		)
	}
}

export default PaymentOption;