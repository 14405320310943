import React from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BlueCheckIcon from '../../images/blue-check-icon.svg';
import LuggageIcon from '../../images/luggage-icon.svg';
import './confirmationHeader.css';

const ConfirmationHeader = (props) => {
	const bookingRef = localStorage.getItem("BookingRef");
	const phoneNumber = localStorage.getItem("PhoneNumber");
	let environment = localStorage.getItem("Environment");

	const { t } = useTranslation();

	let MVPDomain = "https://my.villaplustest.com";
	if (!environment || environment.toLowerCase() == "production") {
		MVPDomain = "https://my.villaplus.com";
	}

	return (
		<div className="bc-header-wrap">
			<div className="content-container bc-header">
				<div className="row">
					<div className="col-12">
						<div className="bc-title">
							<div className="bc-title-text">{t("BOOKING_CONFIRMED")}</div>
							<div className="bc-blue-check">
								<img alt="" className="bc-blue-check-icon" src={BlueCheckIcon} />
							</div>
						</div>
						<div className="bc-booking-ref">{t("BOOKING_NUMBER")}: {bookingRef}</div>
						<div className="bc-mvp-info">
							<div className="bc-mvp-info-text">{t("MVP_INFO")}</div>
							<form action={MVPDomain + "/dashboard"} method="post" className="d-inline-block">
								<input hidden readOnly name="bookingRef" id="bookingRef" type="text" value={bookingRef} />
								<input hidden readOnly name="mobile" id="mobile" type="text" value={phoneNumber} />
								<button className="btn btn-transparent bc-mvp-link" type="submit" id="mvp">
									<img alt="" className="bc-mvp-link-icon" src={LuggageIcon} />
									<span className="bc-mvp-link-text">{t("MANAGE_BOOKING")}</span>
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConfirmationHeader;