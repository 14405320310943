import React, { useState, useEffect } from 'react';
import { GetBookingSummary, GetBookingInvoice, GetInventoryDetails, GetAccommodationImages, GetAdwDescription } from '../../services/SummaryService';
import AccommodationImages from './AccommodationImages';
import AccommodationInfo from './AccommodationInfo';
import PriceBreakdown from './PriceBreakdown';
import MobileSummary from './MobileSummary';
import BookSafe from './BookSafe';
import useBus, { dispatch } from 'use-bus'
import CloseButton from '../shared/CloseButton';
import './summary.css';
import { useTranslation } from 'react-i18next';
import DirectDebit from './DirectDebit';
import NavigationNext from '../shared/navigation/NavigationNext';
import { useHistory } from 'react-router-dom';


const Summary = (props) => {
	const IMAGES_RESOLUTION = "1920 X 1277";
	const THUMBNAILS_RESOLUTION = "585 X 389";

	const [summaryDetails, setSummaryDetails] = useState({ data: null, isLoaded: false, error: false });
	const [adwDescription, setAdwDescription] = useState(null)
	const [inventoryDetails, setInventoryDetails] = useState({ data: null, isLoaded: false, error: false });
	const [accommodationImages, setAccommodationImages] = useState({ images: null, thumbnails: null, isLoaded: false, error: false });
	const [invoice, setInvoice] = useState({ data: null, isLoaded: false, error: false, isReloading: false });
	const [mobileSummaryActive, setMobileSummaryActive] = useState(false);
	const { t } = useTranslation();
	let history = useHistory();

	useEffect(() => {
		getSummary();
		getAdwDescription();
	}, []);

	useEffect(() => {
		let mainImageUrl = null;
		if (accommodationImages && accommodationImages.images && accommodationImages.images.length > 0)
			mainImageUrl = accommodationImages.images[0]?.url;

		if (summaryDetails.data && inventoryDetails.data) {
			props.setSummaryData({
				villaName: inventoryDetails.data.villaName,
				startDate: summaryDetails.data.startDate,
				returnDate: summaryDetails.data.returnDate,
				mainImage: mainImageUrl,
				centre: inventoryDetails.data.centreName,
				resortName: inventoryDetails.data.resortName,
				nights: summaryDetails.data.duration,
				adults: summaryDetails.data.guests.Adults,
				children: summaryDetails.data.guests.Children,
				infants: summaryDetails.data.guests.Infants,
				bedrooms: inventoryDetails.data.bedrooms,
				holidayType: "Villa-ON"
			});
		}
	}, [summaryDetails, inventoryDetails, accommodationImages]);

	useEffect(() => {
		if (summaryDetails.isLoaded && summaryDetails.data) {
			getInventory(summaryDetails.data.inventoryResource);
			getInvoice(false);
		}
	}, [summaryDetails]);

	useEffect(() => {
		if (inventoryDetails.data) {
			getImages(inventoryDetails.data.imagesResource);
		}
	}, [inventoryDetails]);

	useEffect(() => {
		if (accommodationImages.images && accommodationImages.images.length > 0) {
			localStorage.setItem("MainImageUrl", accommodationImages.images[0].url);
		}
	}, [accommodationImages]);

	useBus('updateInvoice', () => {
		getInvoice(true);
	}, [invoice])

	useBus('onCurrencyChanged', () => {
		getInvoice(true);
	}, [invoice])

	function getSummary() {
		let guests = localStorage.getItem("Guests");
		GetBookingSummary()
			.then(response => {
				let updatedSummary = { ...response.data, guests: JSON.parse(guests) };
				setSummaryDetails({ data: updatedSummary, isLoaded: true, error: false });
				localStorage.setItem("ReturnDate", response.data.returnDate);
			})
			.catch(error => {
				console.error(error);
				setSummaryDetails({ data: null, isLoaded: true, error: true });
			});
	}

	function getInventory(inventoryResource) {
		GetInventoryDetails(inventoryResource)
			.then(response => {
				localStorage.setItem("Brand", response.data.brand);
				localStorage.setItem("VillaName", response.data.villaName);
				localStorage.setItem("CheckInTime", response.data.checkInTime);
				localStorage.setItem("CheckOutTime", response.data.checkOutTime);
				localStorage.setItem("CentreName", response.data.centreName);
				localStorage.setItem("ResortName", response.data.resortName);
				sessionStorage.setItem("ServicedBySupplier", response.data.servicedBySupplier);
				setInventoryDetails({ data: response.data, isLoaded: true, error: false });
			})
			.catch(error => {
				console.error(error);
				setInventoryDetails({ data: null, isLoaded: true, error: true });
			});
	}

	function getAdwDescription() {
		GetAdwDescription()
			.then((response) => {
				setAdwDescription(response.data)
			})
	}

	function getInvoice(isReloading) {
		setInvoice({ data: null, isLoaded: false, error: false, isReloading: isReloading });
		GetBookingInvoice()
			.then(response => {
				setInvoice({ data: response.data, isLoaded: true, error: false, isReloading: isReloading });
				localStorage.setItem("TotalCost", response.data.totalCosts[0].amount);
				dispatch('updateTotalCost');
			})
			.catch(error => {
				console.error(error);
				setInvoice({ data: null, isLoaded: true, error: true, isReloading: isReloading });
			});
	}

	function getImages(imagesResource) {
		GetAccommodationImages(imagesResource)
			.then(response => {
				let processedImages = [];
				let processedThumbnails = [];

				response.data.forEach((images) => {
					images.images.forEach((image) => {
						let resolution = image.resolution.width + " X " + image.resolution.height;
						if (images.type === "Image" && resolution === IMAGES_RESOLUTION) {
							processedImages.push({
								description: images.description,
								orderIndex: images.orderIndex,
								resolution: resolution,
								type: images.type,
								url: image.file
							})
						}
						else if (images.type === "Image" && resolution === THUMBNAILS_RESOLUTION) {
							processedThumbnails.push({
								description: images.description,
								orderIndex: images.orderIndex,
								resolution: resolution,
								type: images.type,
								url: image.file
							})
						}
							
					})
				})
				processedImages = processedImages.sort((a, b) => {
					return a.orderIndex - b.orderIndex
				});
				processedThumbnails = processedThumbnails.sort((a, b) => {
					return a.orderIndex - b.orderIndex
				});

				setAccommodationImages({
					images: processedImages,
					thumbnails: processedThumbnails,
					isLoaded: true,
					error: false
				});
			})
			.catch(error => {
				console.error(error);
				setAccommodationImages({ images: null, thumbnails: null, isLoaded: true, error: true });
			});
	}

	function setActive() {
		setMobileSummaryActive(!mobileSummaryActive);
		if (!mobileSummaryActive) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}
	}

	let content = (
		<>
			<MobileSummary
				setActive={setActive}
				inventory={inventoryDetails}
				summary={summaryDetails}
				invoice={invoice}
			/>
			<div className={"booking-summary-wrap" + (mobileSummaryActive ? " active" : "")}>
				<div className="booking-summary card">
					<div className="summary-heading">
						<span>{t("SUMMARY_HEADING")}</span>
						<CloseButton
							customClass="d-md-none close-mobile-summary"
							clickHandler={setActive}
						/>
					</div>
					<AccommodationImages images={accommodationImages} />
					<div className="summary-text-content p-3 p-md-4">
						<AccommodationInfo
							inventory={inventoryDetails}
							summary={summaryDetails}
						/>
						{
							<div className="mt-0 mb-3 d-none d-md-block">
							<NavigationNext />
								</div>
						}
						<PriceBreakdown invoice={invoice} adwDescription={adwDescription}/>
						<DirectDebit />
						<BookSafe />
					</div>
				</div>
			</div>
		</>
	);
	return content;
}

export default Summary;