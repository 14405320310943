const currencySymbols = {
	GBP: "£",
	EUR: "€",
	USD: "$"
}

export default {
	GetCurrencySymbol(currency) {		
		return currencySymbols[currency];
		}
}