function LoadGoogleMapsScript() {
	const hostName = window.location.hostname;
	let apiKey = "";

	switch (hostName.toLowerCase()) {
		case "book.villaplus.com":
			apiKey = "AIzaSyBT-DyQ8h1hEaM1Nw19Hn2totpcMwZfmbw";
			break;
		case "book.villaplustest.com":
			apiKey = "AIzaSyDwNG9g02cJDzLjIpY628kk_IBaoQE9-r0";
			break;
		default:
			return false;
	}

	const existingScript = document.getElementById('googleMaps');
	if (!existingScript) {
		const script = document.createElement('script');
		script.src = 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '=&libraries=places';
		script.id = 'googleMaps';
		document.body.appendChild(script);
	}
}

export { LoadGoogleMapsScript };