import CountryCodesEnglish from '../jsonData/Translations/countryCodes.en.json';
import CountryCodesDutch from '../jsonData/Translations/countryCodes.nl.json';
import CountryCodesGerman from '../jsonData/Translations/countryCodes.de.json';
import CountryCodesSpanish from '../jsonData/Translations/countryCodes.es.json';
import CountryCodesFrench from '../jsonData/Translations/countryCodes.fr.json';
import CountryCodesPortuguese from '../jsonData/Translations/countryCodes.pt.json';
import CountryCodesItalian from '../jsonData/Translations/countryCodes.it.json';
import CountryCodesDanish from '../jsonData/Translations/countryCodes.hr.json';
import CountryCodesCroatian from '../jsonData/Translations/countryCodes.da.json';
import CountryCodesPolish from '../jsonData/Translations/countryCodes.pl.json';
import CountryCodesLithuanian from '../jsonData/Translations/countryCodes.lt.json';
import CountryCodesFinnish from '../jsonData/Translations/countryCodes.fi.json';
import CountryCodesEstonian from '../jsonData/Translations/countryCodes.et.json';
import CountryCodesNorwegian from '../jsonData/Translations/countryCodes.nb.json';
import CountryCodesHungarian from '../jsonData/Translations/countryCodes.hu.json';
import CountryCodesGreek from '../jsonData/Translations/countryCodes.el.json';
import CountryCodesBulgarian from '../jsonData/Translations/countryCodes.bg.json';
import CountryCodesCzech from '../jsonData/Translations/countryCodes.cs.json';
import CountryCodesRomanian from '../jsonData/Translations/countryCodes.ro.json';
import CountryCodesSwedish from '../jsonData/Translations/countryCodes.sv.json';
import CountryCodesSerbian from '../jsonData/Translations/countryCodes.sr.json';
import CountryCodesSlovak from '../jsonData/Translations/countryCodes.sk.json';
import CountryCodesLatvian from '../jsonData/Translations/countryCodes.lv.json';
import CountryCodesMaltese from '../jsonData/Translations/countryCodes.mt.json';
import CountryCodesSlovenian from '../jsonData/Translations/countryCodes.sl.json';
import cookieHelper from '../helpers/CookieHelper';

const countriesMapping = {
	"en": CountryCodesEnglish,
	"nl": CountryCodesDutch,
    "de": CountryCodesGerman,
    "es": CountryCodesSpanish,
    "fr": CountryCodesFrench,
    "pt": CountryCodesPortuguese,
    "it": CountryCodesItalian,
    "hr": CountryCodesCroatian,
    "da": CountryCodesDanish,
    "pl": CountryCodesPolish,
    "lt": CountryCodesLithuanian,
    "fi": CountryCodesFinnish,
    "et": CountryCodesEstonian,
    "nb": CountryCodesNorwegian,
    "hu": CountryCodesHungarian,
    "el": CountryCodesGreek,
    "bg": CountryCodesBulgarian,
    "cs": CountryCodesCzech,
    "ro": CountryCodesRomanian,
    "sv": CountryCodesSwedish,
    "sr": CountryCodesSerbian,
    "sk": CountryCodesSlovak,
    "lv": CountryCodesLatvian,
    "mt": CountryCodesMaltese,
    "sl": CountryCodesSlovenian
}
const selectedCountryMapping = {
    "en": "GB",
    "nl": "NL",
    "de": "DE",
    "es": "ES",
    "fr": "FR",
    "pt": "PT",
    "it": "IT",
    "hr": "hr",
    "da": "DK",
    "pl": "PL",
    "lt": "LT",
    "fi": "FI",
    "et": "ET",
    "nb": "NB",
    "hu": "HU",
    "el": "EL",
    "bg": "BG",
    "cs": "CS",
    "ro": "RO",
    "sv": "SE",
    "sr": "SR",
    "sk": "SK",
    "lv": "LV",
    "mt": "MT",
    "sl": "SI"
}
export default {
    fetchCountryPhoneDetails: () => {
        const selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

        if (!selectedLanguage) {
            return null;
        }

        let countryLabelsData = countriesMapping[selectedLanguage ? selectedLanguage : "en"]
        if (!countryLabelsData)
            countryLabelsData = countriesMapping["en"];

        var selectedCountryCode = selectedCountryMapping[selectedLanguage];
        var countryDetails = countryLabelsData[selectedCountryCode ? selectedCountryCode : "GB"];

        if (countryDetails && countryDetails.secondary)
            return { key: selectedCountryCode, code: countryDetails.secondary }

        return null;
    },
}