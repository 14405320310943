import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import EstablishedLogo from '../../images/established.svg';
import SecurePayDisclaimer from '../../components/shared/securePayDisclaimer/SecurePayDisclaimer';
import './footer.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cookieHelper from '../../helpers/CookieHelper';
import useABTest from '../../hooks/useABTest';

const Footer = () => {
	const [showSecurePayDisclaimer, setShowSecurePayDisclaimer] = useState(true);

	const { t } = useTranslation();
	const location = useLocation();

	useEffect(() => {
		if (location && (location.pathname === "/confirmation" || location.pathname === "/booking-confirmation")) {
			setShowSecurePayDisclaimer(false);
		}
	}, [location]);

	const language = cookieHelper.getCookie("SelectedLanguageOption");
	const showLinks = (language === null || language === "en");
	const cultureParam = (language === null || language === "en") ? "" : ("?culture=" + language);


	return (
		<>
			{showSecurePayDisclaimer &&
				<div className="secure-pay-disclaimer-container mobile-padding-top-20">
					<SecurePayDisclaimer />
				</div>
			}
			<footer className="px-4 py-4 py-md-1">
				<div className={`content-container ${window.innerWidth < 768 ? "ab-bottom-padding" : ""}`}>
					<div className="row w-100 align-items-center">
						<div className="col-md-9">
							<ul className="footer-links list-group list-group-horizontal-md dot-list">
								<li className={`${showLinks ? "" : "d-none"}`}>
									<a href={"https://www.villaplus.com/about-us" + cultureParam} target="_blank" className="link">
										{t("ABOUT_US")}
									</a>
								</li>
								<li className={`${showLinks ? "" : "d-none"}`}>
									<a href={"https://www.villaplus.com/contact-us" + cultureParam} target="_blank" className="link">
										{t("CONTACT_US")}
									</a>
								</li>
								<li className={`${showLinks ? "" : "d-none"}`}>
									<a href={"https://www.villaplus.com/website-terms-of-use" + cultureParam} target="_blank" className="link">
										{t("WEBSITE_TERMS_OF_USE")}
									</a>
								</li>
								<li className={`${showLinks ? "" : "d-none"}`}>
									<a href={"https://www.villaplus.com/privacy" + cultureParam} target="_blank" className="link">
										{t("PRIVACY")}
									</a>
								</li>
								<li>
									<Link className="link" to="/booking-conditions" target="_blank">{t("BOOKING_CONDITIONS")}</Link>
								</li>
							</ul>
						</div>
						<div className="col-md-3 d-none d-md-flex justify-content-end">
							<img className="established-logo img-fluid"
								src={EstablishedLogo}
								alt="VP Established logo"
							/>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer;