import axios from 'axios';

async function GetFirstAvailableAirport(airportCodes) {
	let arrivalDate = localStorage.getItem("DepartureDate");
	let departureDate = localStorage.getItem("ReturnDateForTransfers");
	let centreResource = localStorage.getItem("CentreResource");
	let inventoryResource = localStorage.getItem("InventoryResource");
	let currencyCode = localStorage.getItem("CurrencyCode");

	return await axios.get(`/api/Transfers/GetFirstAvailableAirport?arrivalDate=${arrivalDate}&departureDate=${departureDate}&airportCodes=${JSON.stringify(airportCodes)}&centreResource=${encodeURIComponent(centreResource)}&inventoryResource=${encodeURIComponent(inventoryResource)}&currencyCode=${currencyCode}`);
}

async function GetTransfers(airportCode) {
	let arrivalDate = localStorage.getItem("DepartureDate");
	let departureDate = localStorage.getItem("ReturnDateForTransfers");
	let centreResource = localStorage.getItem("CentreResource");
	let inventoryResource = localStorage.getItem("InventoryResource");
	let currencyCode = localStorage.getItem("CurrencyCode");

	return await axios.get(`/api/Transfers/SearchTransfers?arrivalDate=${arrivalDate}&departureDate=${departureDate}&airportCode=${airportCode}&centreResource=${encodeURIComponent(centreResource)}&inventoryResource=${encodeURIComponent(inventoryResource)}&currencyCode=${currencyCode}`);
}

async function ReserveTransfer(reserveLink, airportName, accomodationName) {
	let token = localStorage.getItem("ECommerceToken");
	let eCommerceResource = localStorage.getItem("ECommerceBookingLink");
	let invResource = localStorage.getItem("InventoryResource");

	return await axios.get(`/api/Transfers/ReserveTransfer?reserveLink=${encodeURIComponent(reserveLink)}&airportName=${encodeURIComponent(airportName)}&accomodationName=${encodeURIComponent(accomodationName)}&eCommerceResource=${encodeURIComponent(eCommerceResource)}&inventoryResource=${encodeURIComponent(invResource)}`,{ 'headers': { 'token': token } });
}

async function RemoveTransfer(transferResource) {
	let token = localStorage.getItem("ECommerceToken");
	let eCommerceResource = localStorage.getItem("ECommerceBookingLink");

	return await axios.get(`/api/Transfers/RemoveTransfer?transferResource=${encodeURIComponent(transferResource)}&eCommerceResource=${encodeURIComponent(eCommerceResource)}`,
		{ 'headers': { 'token': token } }
	);
}


export { GetTransfers, ReserveTransfer, RemoveTransfer, GetFirstAvailableAirport };
