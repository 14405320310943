
function sortInternalCosts(paymentCosts, currencyCode) {
	let filteredPaymentCosts = paymentCosts.filter((x) => {
		return x.currencyCode === currencyCode
	});

	var result = filteredPaymentCosts.sort((a, b) => {
		if (a.paymentAmount < b.paymentAmount) {
			return -1;
		} else {
			return 1;
		}
	})

	return result;
}

export default {

	sortPaymentOptionsByCost: (paymentOptions) => {
		if (!paymentOptions) {
			return null;
		}

		let currencyCode = localStorage.getItem("CurrencyCode");

		if (!currencyCode)
			currencyCode = "GBP";

		const result = paymentOptions.sort((a, b) => {
			const previous = sortInternalCosts(a.paymentAmounts, currencyCode);
			const next = sortInternalCosts(b.paymentAmounts, currencyCode);
			if (previous[0].paymentAmount < next[0].paymentAmount) {
				return -1;
			} else {
				return 1;
			}
		});

		return result;
	},
	addProcessorSdkToDOM: (paymentProcessorSdk) => {
		let existingWrapper = document.getElementById("sdk-elements-wrapper");
		if (existingWrapper) {
			document.body.removeChild(existingWrapper);
		}

		const domParser = new DOMParser();
		const domElements = domParser.parseFromString(paymentProcessorSdk, "text/html");

		let links = [];
		let sdkScripts = [];
		let variableScripts = [];

		const linkTags = domElements.getElementsByTagName("link");
		for (let link of linkTags) {
			links.push(link);
		}

		const scriptTags = domElements.getElementsByTagName("script");
		for (let script of scriptTags) {
			let newScript = document.createElement("script");
			newScript.type = script.type;
			newScript.async = script.async;
			newScript.defer = script.defer;
			if (script.src) {
				newScript.src = script.src;
				sdkScripts.push(newScript);
			} else {
				newScript.innerHTML = script.innerHTML;
				variableScripts.push(newScript);
			}
		}

		let allElements = links.concat(variableScripts).concat(sdkScripts);
		let wrapper = document.createElement("div");
		wrapper.id = "sdk-elements-wrapper";

		for (let element of allElements) {
			wrapper.appendChild(element);
		}

		document.body.appendChild(wrapper);

		window.appInsights = [];
	},
	getDiscountedAmount: (paymentOptions) => {
		if (!paymentOptions) {
			return null;
		}
		let currencyCode = localStorage.getItem("CurrencyCode");

		if (!currencyCode)
			currencyCode = "GBP";

		let discountedPaymentOption = paymentOptions.filter((x) => {
			return x.displayName.toLowerCase() === "full amount" && x.voucherApplied != null
		});

		if (!discountedPaymentOption || discountedPaymentOption.length === 0)
			return null;

		let discountedPaymentAmount = discountedPaymentOption[0].paymentAmounts.filter((x) => {
			return x.currencyCode === currencyCode
		});

		if (!discountedPaymentAmount || discountedPaymentAmount.length === 0)
			return null;
		else
			return discountedPaymentAmount[0].paymentAmount;
	}
}