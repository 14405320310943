import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { appInsights } from '../../../services/AppInsights';
import React from 'react';
import useABTest from '../../../hooks/useABTest';

const Navigation = (props) => {
	const { buttonKey, pathToNextPage, commitFunction, completedStep } = props;
	const { t } = useTranslation();
	let history = useHistory();


	function navigateBack() {
		appInsights.trackEvent({
			name: 'Navigation',
			properties: {
				way: 'Back',
				fromPage: window.location.pathname,
				toPage: pathToNextPage
			}
		});

		history.goBack();
	}

	function navigateForward() {
		appInsights.trackEvent({
			name: 'Navigation',
			properties: {
				way: 'Forward',
				fromPage: window.location.pathname,
				toPage: pathToNextPage
			}
		});

		if (commitFunction) {
			commitFunction()
				.then(response => {
					if (response) {
						history.push(pathToNextPage);
						markStepAsComplete();
					}
				})
				.catch((error) => {
					appInsights.trackException({ error: new Error(error), severityLevel: "Error" });
					console.error(error);
				});
		} else {
			markStepAsComplete();
			history.push(pathToNextPage);
		}

		if (window.innerWidth < 768)
			window.scrollTo(0, 0);
	}

	function markStepAsComplete() {
		sessionStorage.setItem(completedStep, true);
	}

	return (
		<div className="row align-items-center pr-3">
			<div className="col-4 justify-content-start d-none d-md-flex">
				<div className="btn-directional-back text-nowrap" onClick={navigateBack}>
					&#8592;&nbsp;&nbsp;&nbsp;{t("BACK_BUTTON")}
				</div>
			</div>
			{buttonKey ?
				<div id="old-next-button" className="d-flex justify-content-end  d-none d-sm-block d-md-none" style={{ paddingRight: "20px" }}>
					<button className="btn-directional-next text-nowrap" onClick={navigateForward}>
						{t(buttonKey)}
					</button>
				</div>
				:
				null
			}
		</div>
	);
}

export default Navigation;