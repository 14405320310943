import React, { useState, useEffect } from 'react';
import './AirportSelector.css';
import { useTranslation } from 'react-i18next';
import CaretDownIcon from '../../../images/icons-caret-down-blue.svg';
import AirportIcon from '../../../images/icons-airport.svg';
import CheckIcon from '../../../images/blue-check-icon.svg';

const AirportSelector = (props) => {
	const {
		airports, changeAirport, defaultAirportCode
	} = props;

	const [selectedAirport, setSelectedAirport] = useState([]);
	const [showAirportSelection, setShowAirportSelection] = useState(false);

	const { t } = useTranslation();

	document.addEventListener("click", (event) => {
		let concernedElement = document.querySelector(".airportSelectorDiv");

		if (concernedElement != null && !concernedElement.contains(event.target)) 
			setShowAirportSelection(false);		
	});

	useEffect(() => {
		const defaultAirport = airports.filter(airport => airport.Code === defaultAirportCode)[0]
		setSelectedAirport(defaultAirport);	
		setSelectedAirportInLocalStorage(defaultAirport)
	}, []);

	function onClick() {
		if (!showAirportSelection) {
			setShowAirportSelection(true);
		}
		else { setShowAirportSelection(false); }
	}

	function selectAirport(airport) {
		setSelectedAirport(airport);
		setShowAirportSelection(false);
		setSelectedAirportInLocalStorage(airport)
		changeAirport(airport);
    }

	function setSelectedAirportInLocalStorage(airport) {
		localStorage.setItem("SelectedAirport", airport.Description);
		localStorage.setItem("SelectedAirportCode", airport.Code);
	}

	let content = (
		<>
			<div className="position-relative my-4 airportSelectorDiv">
				<div id="airportSelector" className="position-relative d-flex pointer" onClick={() => onClick()}>
					<div className="airportName text-middle"><img className="airportIcon pr-2" src={AirportIcon} />{selectedAirport.Description}</div>
					<div className="caretDownIcon" >
						<img src={CaretDownIcon} />
					</div>
				</div>
				<span className="airport-placeholder-wrapper"> {t("AIRPORT_SELECTION_PLACEHOLDER")}</span>

				<div id="airportSelectorModal" className={(showAirportSelection ? "" : "d-none")}>
					<div className="airportSelectorModalTitle text-left mb-3">{t("AIRPORT_SELECTION_MODAL_TITLE")}</div>
					<div id="airportSelectorItems" className="text-left">
						{airports.map((airport, index) => {
							return (
								<div key={"airport" + index} id={"airport" + index} className={"row selectorItem " + (selectedAirport.Code === airport.Code ? "activeAirport" : "") + " ml-0 mr-0 mb-2 align-content-center justify-content-end"} onClick={() => selectAirport(airport)}>
									<div className ="col p-0">
										<span className="pl-3">{airport.Description}</span>
									</div>
									<div id={"airport" + index + "-icon"} className={"pl-0 pr-3 " + (selectedAirport.Code === airport.Code ? "" : "d-none")} >
										<img src={CheckIcon} />
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);

	return content;
}

export default AirportSelector;