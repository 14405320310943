import React, { useState, useEffect } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PrivateTransfer from './PrivateTransfer';
import './PrivateTransfersSlider.css';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { ReserveTransfer, RemoveTransfer } from '../../../services/TransfersService';
import useBus, { dispatch } from 'use-bus';
import Spinner from '../../../images/spinner.svg';
import "react-loading-skeleton/dist/skeleton.css";


const PrivateTransfersSlider = (props) => {
	const { transfers } = props;
	const [selectedTransfer, setSelectedTransfer] = useState(null);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("Private Transfers could not be loaded at this time.");
	const [transfersLoading, setTransfersLoading] = useState(false);
	const [currentSlide, setCurrentSlide] = useState(0);

	const villaName = localStorage.getItem("VillaName");
	const airportName = localStorage.getItem("SelectedAirport");
	const airportCode = localStorage.getItem("SelectedAirportCode");

	let bookedTransfers = JSON.parse(sessionStorage.getItem("BookedTransfers")) === null ? [] : JSON.parse(sessionStorage.getItem("BookedTransfers"));

	function onTransfersSeletion(transfer) {
		setSelectedTransfer(transfer);
	}

	function onTransfersModification(transfer, isSelected) {
		setErrorMessage("")
		setIsError(false);
		setTransfersLoading(true);
		if (isSelected) {
			return ReserveTransfer(transfer.reserve, airportName, villaName)
				.then((bookingLink) => {
					bookedTransfers = JSON.parse(sessionStorage.getItem("BookedTransfers")) === null ? [] : JSON.parse(sessionStorage.getItem("BookedTransfers"));
					bookedTransfers.push({ bookingLink: bookingLink.data, reserveLink: transfer.reserve, airportCode: airportCode, airportName: airportName });
					sessionStorage.setItem("BookedTransfers", JSON.stringify(bookedTransfers));
					transfer.bookingLink = bookingLink.data;
					dispatch("updateInvoice");
					setTransfersLoading(false);
					return true;
				})
				.catch(error => {
					setIsError(true);
					setErrorMessage("Could not update private transfers at this time. Please try again later.");
					setTransfersLoading(false);
					return false;
				});
		}
		else {
			return RemoveTransfer(transfer.bookingLink)
				.then(() => {
					bookedTransfers = JSON.parse(sessionStorage.getItem("BookedTransfers")) === null ? [] : JSON.parse(sessionStorage.getItem("BookedTransfers"));
					bookedTransfers = bookedTransfers.filter(x => x.bookingLink !== transfer.bookingLink && x.reserveLink !== transfer.reserve);
					sessionStorage.setItem("BookedTransfers", JSON.stringify(bookedTransfers));
					transfer.bookingLink = "";
					dispatch("updateInvoice");
					setTransfersLoading(false);
					return true;
				})
				.catch(error => {
					setIsError(true);
					setErrorMessage("Could not update private transfers at this time. Please try again later.");
					setTransfersLoading(false);
					return false;
				});
		}
	}

	function SetLocalisationSelector() {
		if (bookedTransfers.length > 0)
			dispatch("disableLocalisationSelector");
		else
			dispatch("enableLocalisationSelector");
	}

	function handleSlideChange(swiper) {
		if (currentSlide != swiper.realIndex) {
			setCurrentSlide(swiper.realIndex);
		}
	}

	let content;

	if (!transfersLoading && transfers && transfers.length > 0) {
		content = (
			<div className="privateTransfersSlider">
				<Swiper
					modules={[Navigation, Pagination]}
					spaceBetween={20}
					slidesPerView={2}
					navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
					centerInsufficientSlides={true}
					pagination={{ clickable: true }}
					initialSlide={currentSlide}
					onSlideChange={handleSlideChange}
					breakpoints={{
						320: {
							spaceBetween: 20,
							slidesPerView: 1,
						},
						576: {
							spaceBetween: 20,
							slidesPerView: 2,
						},
						768: {
							spaceBetween: 20,
							slidesPerView: 1,
						},
						992: {
							spaceBetween: 20,
							slidesPerView: 2,
						},
					}}
				>
					{transfers.map((transfer, i) => {
						return (
							<SwiperSlide key={i}>
								<PrivateTransfer
									transfer={transfer}
									index={i}
									selectedTransfer={selectedTransfer}
									modifyAction={onTransfersModification}
									selectTransfer={onTransfersSeletion}
									isChecked={transfer.isChecked}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
				<div className="swiper-button-prev"></div>
				<div className="swiper-button-next"></div>
			</div>

		);
	}
	else if (transfersLoading) {
		content = (
			//<div style={{ marginLeft: '30px', marginRight: '30px', height: '200px' }} className="row">
			//	<div className="col-6"><Skeleton count={6} /></div>
			//	<div className="col-6"><Skeleton count={6} /></div>
			//</div>
			<img alt="Loading" src={Spinner} className="d-flex mx-auto" style={{ width: '15%' }} />
		)
	}
	else {
		content = <></>;
	}

	return content;
}

export default PrivateTransfersSlider;