import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextInput from '../../shared/controls/TextInput';
import { dispatch } from 'use-bus'
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import AnalyticsHelper from '../../../helpers/AnalyticsHelper';
import formatHelper from '../../../helpers/FormatHelper';
import CloseIcon from '../../../images/button-bin-slim.svg'

const Voucher = (props) => {
	const { setErrorMessage, setSuccessMessage } = props;
	const { t } = useTranslation();

	const [voucher, setVoucher] = useState("");
	const [inputShown, setInputShown] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	let appliedVoucher = localStorage.getItem("AppliedVoucher");

	if (appliedVoucher != null && appliedVoucher !== "") {
		appliedVoucher = JSON.parse(appliedVoucher);
	}

	let history = useHistory();

	function onVoucherClick() {
		AnalyticsHelper.TrackEvent("voucher_code", "displayed");
		setInputShown(true);
	}

	const addVoucher = (e) => {
		setVoucher(e.target.value.trim());
	};

	function submitVoucher() {
		if (!voucher) {
			setErrorMessage(t("BLANK_VOUCHER"));
			return;
		}

		const currencyCode = localStorage.getItem("CurrencyCode");

		if (currencyCode !== "GBP") {
			setErrorMessage(t("INVALID_VOUCHER_CURRENCY"));
			return;
		}

		setIsLoading(true);
		setErrorMessage("");
		setSuccessMessage("");

		localStorage.removeItem("storedSelectedPaymentOption");
		localStorage.removeItem("storedCheckoutResponse");
		sessionStorage.removeItem("SelectedPaymentOption");

		var bookingRef = localStorage.getItem("BookingRef");

		localStorage.removeItem("AppliedVoucher");
		localStorage.setItem("AppliedVoucher", `{"BookingRef":"${bookingRef}", "Code":"${voucher}"}`);

		history.go(0);
		dispatch("updateInvoice");
	
	}

	function onRemoveVoucherClick() {
		localStorage.removeItem("storedSelectedPaymentOption");
		localStorage.removeItem("storedCheckoutResponse");
		sessionStorage.removeItem("SelectedPaymentOption");
		localStorage.removeItem("AppliedVoucher");
		history.go(0);
		dispatch("updateInvoice");
	}

	let content;

	if (isLoading) {
		content = <Skeleton height={45} />
	} else if (inputShown) {
		content = (
			<div className="voucher-box-wrap position-relative">
				<TextInput value={voucher}
					onChange={addVoucher}
					name="vouchercode"
					placeholder={t("VOUCHER_CODE_PLACEHOLDER")}
					showValidationMsg={false}
				/>
				<div className="submit-voucher-button btn btn-transparent" onClick={submitVoucher}>
					<span className="submit-voucher-text">{t("VOUCHER_ADD_CONTROL")}</span>
					<span className="submit-voucher-arrow">&nbsp;&nbsp;&nbsp;&#8594;</span>
				</div>
			</div>
		);
	} else if (appliedVoucher != null && appliedVoucher !== "") {
		content = (
			<div className="row align-items-center ml-0 mr-0" id="applied-voucher">
				<div className="col-10">
					<div>
						<div className="ml-1">
							Voucher applied:
						</div>
						<div className="ml-1" style={{ fontWeight: "700", fontSize: "19px" }}>
							{formatHelper.formatPrice(appliedVoucher.Amount*-1)}
						</div>
					</div>
				</div>
				<div className="col-2 text-right p-0 pointer" onClick={onRemoveVoucherClick}>
					<img src={CloseIcon} alt="" />
				</div>
				
			</div>
		);
	} else {
		content = (
			<div className="toggle-voucher-button pointer font-light text-right"
				onClick={() => onVoucherClick()}
			>
				{t("VOUCHER_CODE_TITLE")}
			</div>
		);
	}


	return content;
	
}

export default Voucher;