import React from 'react';
import { useTranslation } from 'react-i18next';

const PaymentCompleted = (props) => {
	const { t } = useTranslation();
	document.title = props.tabTitle;

	const environment = localStorage.getItem("Environment");
	const domain = environment.toLowerCase() === "production" ? "https://my.villaplus.com" : "https://my.villaplustest.com";

	return (
		<div className="content-container">
			<div className="row w-100">
				<div className="col-12">
					<div className="card grey-colour">
						<div className="card-body">
							<p>{t("PAYMENT_COMPLETED")} <a href={domain} target="_blank">My Villa Plus.</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PaymentCompleted;