import React, { useEffect } from 'react';
import ErrorMessage from '../components/shared/errorMessage/ErrorMessage';
import AnalyticsHelper from '../helpers/AnalyticsHelper';

const Error = (props) => {
	document.title = props.tabTitle;

	useEffect(() => {
		AnalyticsHelper.TrackExpiredReservation();
		AnalyticsHelper.TrackPageLoad("error_load", "Booking Error");
	}, [])

	return (
		<div className="content-container">
			<div className="row w-100">
				<div className="col-12">
					<ErrorMessage message={props.errorMessage} />
				</div>
			</div>
		</div>
	)
}

export default Error;