import React, { useRef, useEffect, useState } from 'react';
import './localisationModal.css';
import { dispatch } from 'use-bus'
import BlueCheckIcon from '../../../images/blue-check-icon.svg';
import cookieHelper from '../../../helpers/CookieHelper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';


const LocalisationModal = (props) => {
	const environment = localStorage.getItem("Environment");
	const { t } = useTranslation();
	const [selectedCurrency, setSelectedCurrency] = useState(localStorage.getItem("CurrencyCode"));
	const [selectedLanguage, setSelectedLanguage] = useState(cookieHelper.getCookie("SelectedLanguageOption") ?? "en");
	const ref = useRef(null);

	const languages = [
		{ name: "English", shortName: "(UK)", code: "en" },
		{ name: "English", shortName: "(US)", code: "en-US" },
		{ name: "Български", shortName: "(BG)", code: "bg" },
		{ name: "Čeština", shortName: "(CS)", code: "cs" },
		{ name: "Dansk", shortName: "(DA)", code: "da" },
		{ name: "Deutsch", shortName: "(DE)", code: "de" },
		{ name: "Español", shortName: "(ES)", code: "es" },
		{ name: "Estonia", shortName: "(ET)", code: "et" },
		{ name: "Eλληνικά", shortName: "(EL)", code: "el" },
		{ name: "Français", shortName: "(FR)", code: "fr" },
		{ name: "Hrvatski", shortName: "(HR)", code: "hr" },
		{ name: "Italiano", shortName: "(IT)", code: "it" },
		{ name: "Latviešu", shortName: "(LV)", code: "lv" },
		{ name: "Lietuvių", shortName: "(LT)", code: "lt" },
		{ name: "Magyar", shortName: "(HU)", code: "hu" },
		{ name: "Malti", shortName: "(MT)", code: "mt" },
		{ name: "Nederlands", shortName: "(NL)", code: "nl" },
		{ name: "Norsk", shortName: "(NB)", code: "nb" },
		{ name: "Polski", shortName: "(PL)", code: "pl" },
		{ name: "Português", shortName: "(PT)", code: "pt" },
		{ name: "Română", shortName: "(RO)", code: "ro" },
		{ name: "Slovenčina", shortName: "(SK)", code: "sk" },
		{ name: "Slovenščina", shortName: "(SL)", code: "sl" },
		{ name: "Srpski", shortName: "(SR)", code: "sr" },
		{ name: "Suomi", shortName: "(FI)", code: "fi" },
		{ name: "Svenska", shortName: "(SV)", code: "sv" }];

	const defaultCurrencyMapping = {
		"GBP": ["en"],
		"USD": ["en-US"],
		"EUR": ["nl", "de", "es", "pt", "fr", "it", "hr", "da", "pl", "lt", "fi", "et", "nb", "hu", "el", "bg", "cs", "ro", "sv", "sr", "sk", "lv", "mt", "sl"],
	}

	let currencyLocalisationData = [{ name: t("EURO_CURRENCY"), symbol: "(€)", code: "EUR" }, { name: t("BRITISH_CURRENCY"), symbol: "(£)", code: "GBP" }, { name: t("DOLLAR_CURRENCY"), symbol: "($)", code: "USD" }];

	let hasTransfers = JSON.parse(sessionStorage.getItem("BookedTransfers"));

	const supportedCurrencies = JSON.parse(localStorage.getItem("SupportedCurrencies"));


	const tabElements = ["language-tab-header", "currency-tab-header", "language-tab-content", "currency-tab-content"];
	let history = useHistory();

	const onLanguageSelected = (language) => {
		setSelectedLanguage(language);
		for (const [key, value] of Object.entries(defaultCurrencyMapping)) {
			if (value.includes(language)) {
				setSelectedCurrency(key);
			}
		}
	}

	const onCurrencySelected = (currency) => {
		setSelectedCurrency(currency);
	}

	const onModalClose = () => {
		cookieHelper.setStrictCookie("SelectedLanguageOption", selectedLanguage, 365);
		localStorage.setItem("CurrencyCode", selectedCurrency);
		document.documentElement.lang = selectedLanguage;
		history.go(0);
	}

	const onTabClick = (headerElementId, contentElementId) => {
		tabElements.forEach((tabElementId) => {
			var element = document.getElementById(tabElementId);

			if (element) {
				if (tabElementId === headerElementId || tabElementId === contentElementId) {
					element.classList.add("active")
				}
				else {
					element.classList.remove("active");

				}
			}
		})
	}




	const { display } = props;
	return (
		<div className={(display ? "show show-modal " : "") + "modal"}>
			<div className="modal-dialog modal-dialog-centered modal-max-size p-3" role="document">
				<div className="modal-content" ref={ref}>
					<div className="modal-body modal-border pt-4 px-1">
						<div className="row margin-10 pb-3 d-none d-sm-none d-md-flex lc-language-currency-container">
							<div id="languageDiv">
								<div className="lc-language-title">{t("LANGUAGE")}</div>
								<div className="lc-language-content">
									{languages.map((language, index) => {
										return (
											<div key={language + "_" + index} className={(language.code.toLowerCase() === selectedLanguage.toLowerCase() ? "selected " : "font-weight-400 ") + "pt-2 pb-2 pointer padding-left-5 lc-option text-left"} onClick={() => { onLanguageSelected(language.code) }}>
													<span className="font-size-17">{language.name}</span><span className="font-size-15"> {language.shortName}</span>
												{language.code === selectedLanguage ?
													<div className="float-right padding-right-7">
														<img style={{ paddingRight: "10px", marginBottom: "4px"}} src={BlueCheckIcon} />
													</div>
													:
													null
												}
											</div>
										)
									})}
								</div>
							</div>
							<div className="vertical-line">
							</div>
							<div id="currencyDiv">
								<div className="lc-currency-title">{t("CURRENCY")}</div>
								<div className="lc-currency-content">
									{supportedCurrencies.map((currency, index) => {
										let currencyToMap = currencyLocalisationData.find((x) => { return x.code == currency })
										if (!currencyToMap)
											return;

										return (
											<div key={currencyToMap + "_" + index} className={(currencyToMap.code === selectedCurrency ? "selected " : "font-weight-400") + "  padding-left-5 pt-2 pb-2 pointer lc-option"} onClick={() => { onCurrencySelected(currencyToMap.code) }}>
												<span className="font-size-17">{currencyToMap.name}</span><span className="font-size-15"> {currencyToMap.symbol}</span>
												{currencyToMap.code === selectedCurrency ?
													<div className="float-right padding-right-7">
														<img style={{ paddingRight: "10px", marginBottom: "4px" }}  src={BlueCheckIcon} />
													</div>
													:
													null
												}
											</div>
										)
									})}
								</div>
							</div>
						</div>
						<div className="d-md-none">
							<ul className="nav nav-tabs">
								<li className="nav-item">
									<a className="nav-link custom-tab pointer font-weight-400 active" id="language-tab-header" onClick={() => { onTabClick("language-tab-header", "language-tab-content") }} data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">{t("LANGUAGE")}</a>
								</li>
								<li className="nav-item ">
									<a className="nav-link custom-tab font-weight-400 pointer" id="currency-tab-header" onClick={() => { onTabClick("currency-tab-header", "currency-tab-content") }} data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false">{t("CURRENCY")}</a>
								</li>
							</ul>

							<div className="tab-content padding-left-50">
								<div className="tab-pane active pt-4" id="language-tab-content" role="tabpanel" aria-labelledby="home-tab">
									<div className={hasTransfers && hasTransfers.length > 0 ? "d-none" : "row"}>
										{languages.map((language, index) => {
											return (
												<div key={language + "_" + index} className={(language.code === selectedLanguage.toLowerCase() ? "selected " : "") + "col-12 pt-2 pb-2 pointer"} onClick={() => { onLanguageSelected(language.code) }}>
													<span className="font-size-17">{language.name}</span><span className="font-size-15"> {language.shortName}</span>
													{language.code === selectedLanguage ?
														<div className="float-right">
															<img src={BlueCheckIcon} />
														</div>
														:
														null
													}
												</div>
											)
										})}
									</div>
								</div>
								<div className="tab-pane pt-4" id="currency-tab-content" role="tabpanel" aria-labelledby="profile-tab">
									<div className="row">
										{supportedCurrencies.map((currency, index) => {
											let currencyToMap = currencyLocalisationData.find((x) => { return x.code == currency })
											if (!currencyToMap)
												return;

											return (
												<div key={currencyToMap + "_" + index} className={(currencyToMap.code === selectedCurrency ? "selected " : "") + "col-12 pt-2 pb-2 pointer"} onClick={() => { onCurrencySelected(currencyToMap.code) }}>
													<span className="font-size-17">{currencyToMap.name}</span><span className="font-size-15"> {currencyToMap.symbol}</span>
													{currencyToMap.code === selectedCurrency ?
														<div className="float-right">
															<img src={BlueCheckIcon} />
														</div>
														:
														null
													}
												</div>
											)
										})}
									</div>
								</div>
							</div>

						</div>
						<div className="row pt-3">
							<div className="col-12 pb-2 text-center">
								<button type="button" className="btn modal-button" onClick={onModalClose}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LocalisationModal;