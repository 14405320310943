import React from 'react';
import closeButton from '../../images/close-button.svg';

const CloseButton = (props) => {
	let { customClass, clickHandler } = props;
	customClass = customClass || "";

	return (
		<img
			alt=""
			className={"close-button " + customClass}
			src={closeButton}
			onClick={clickHandler}
		/>
	);
}

export default CloseButton;