import React, { useState } from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import Summary from '../components/summary/Summary';
import './layout.css';
import { useHistory } from 'react-router-dom';
import LocalisationModal from '../components/shared/LocalisationModal/LocalisationModal';
import useBus from 'use-bus';

function Layout(props) {
	const { setSummaryData, showSummary } = props;
	const [displayLocalisationModal, setDisplayLocalisationModal] = useState(false);
	const isPaymentCompleted = sessionStorage.getItem("PaymentCompleted") === "true";

	let history = useHistory();
	const bookingStepUrls = ["/", "/contactdetails", "/payment", "/extras"];
	let isBookingStep = bookingStepUrls.find((x) => { return x === history.location.pathname }) != undefined;
	const isManualConfirmationNavigation = !isPaymentCompleted && history.location.pathname == "/booking-confirmation";

	useBus('openLocalisationModal', async () => {
		setDisplayLocalisationModal(true);
	})

	useBus("closeLocalisationModal", async () => {
		setDisplayLocalisationModal(false);
	})

	if (showSummary && (isBookingStep || isManualConfirmationNavigation) && !isPaymentCompleted) {
		return (
			<>
				<Header />
				<LocalisationModal display={displayLocalisationModal} />
				<div className="content-container content-wrap">
					<div className="content-col-l">
						{props.children}
					</div>
					<div className="content-col-r">
						<Summary setSummaryData={setSummaryData} />
					</div>
				</div>
				<Footer />
			</>
		);
	} else {
		return (
			<>
				<Header />
				<div>
					{props.children}
				</div>
				<Footer />
			</>
		);
	}
}

export default Layout;