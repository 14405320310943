import React from 'react';
import { useTranslation } from 'react-i18next';

const NoTransfersForAirport = () => {
	const { t } = useTranslation();

	let content = (
		<>
			<div style={{ padding: "0 2.25rem" }}>{t("NO_TRANSFERS")}</div>
		</>
	);

	return content;
}

export default NoTransfersForAirport;