import React from 'react';
import { useTranslation } from 'react-i18next';


const LateBooking = (props) =>{
	document.title = props.tabTitle;
	const { t } = useTranslation();

	return (
		<div className="content-container">
			<div className="row w-100">
				<div className="col-12">
					<div className="card grey-colour">
						<div className="card-body">
							{t("LATE_BOOKING_MESSAGE")}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LateBooking;