import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import FormatHelper from '../../helpers/FormatHelper';
import { GetTransactions } from '../../services/CheckoutService';
import DirectDebitIcon from '../../images/direct-debit.svg';
import ConfirmationPriceBreakdown from './ConfirmationPriceBreakdown';
import './confirmationBody.css';
import { appInsights } from '../../services/AppInsights';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';
import cookieHelper from '../../helpers/CookieHelper';

const ConfirmationBody = () => {
	const bookingRef = localStorage.getItem("BookingRef");
	const phoneNumber = localStorage.getItem("PhoneNumber");
	const currencyCode = localStorage.getItem("CurrencyCode");
	const selectedAirport = localStorage.getItem("SelectedAirport");
	const selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

	const [isLoaded, setIsLoaded] = useState(false);

	const extrasTranslationMapping = [
		{ type: "z-bed", code: "Z_BED" },
		{ type: "free starter pack", code: "FREE_STARTER_PACK" },
		{ type: "starter pack", code: "STARTER_PACK" },
		{ type: "cot", code: "COT" },
		{ type: "high chair", code: "HIGH_CHAIR" },
		{ type: "pool fence", code: "POOL_FENCE" }
	];

	const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
	const [holidayDetails, setHolidayDetails] = useState({
		startDate: null,
		returnDate: null,
		checkInTime: null,
		checkOutTime: null,
		duration: null,
		guests: null,
		villaName: null,
		villaImageUrl: null,
		centreName: null
	});
	const [pricingDetails, setPricingDetails] = useState({
		totalCost: null,
		paidByCard: null,
		remainingToPay: null,
		voucherAmount: null,
		voucherName: null,
		balanceDueDate: null,
		showDDSignposting: null
	});
	const [costLines, setCostLines] = useState([]);
	const [extrasList, setExtrasList] = useState(null);
	const [transfersList, setTransfersList] = useState(null);
	const [showIncludedPart, setShowIncludedPart] = useState(false);

	const { t } = useTranslation();
	let environment = localStorage.getItem("Environment");

	let MVPDomain = "https://my.villaplustest.com";
	if (!environment || environment.toLowerCase() == "production") {
		MVPDomain = "https://my.villaplus.com";
	}

	const [transferBookings, setTransferBookings] = useState([]);

	useEffect(() => {
		AnalyticsHelper.TrackFlexibleDurationConfirmation();
		updateHolidayDetails();
		updatePricingDetails();
		updateExtras();
	}, []);

	function updateHolidayDetails() {
		const guestsJson = JSON.parse(localStorage.getItem("Guests"));

		setHolidayDetails({
			startDate: FormatHelper.formatDate(new Date(localStorage.getItem("DepartureDate"))),
			returnDate: FormatHelper.formatDate(new Date(localStorage.getItem("ReturnDate"))),
			checkInTime: localStorage.getItem("CheckInTime"),
			checkOutTime: localStorage.getItem("CheckOutTime"),
			duration: localStorage.getItem("Duration"),
			guests: guestsJson.Adults + guestsJson.Children + guestsJson.Infants,
			villaName: "Villa " + localStorage.getItem("VillaName"),
			villaImageUrl: localStorage.getItem("MainImageUrl"),
			centreName: localStorage.getItem("CentreName")
		});
	}

	function updatePricingDetails() {
		GetTransactions()
			.then((response) => {
				try {
					let responseData = JSON.stringify(response.data)
					appInsights.trackEvent({
						name: "ClientSide: GetTransactionsCalled",
						properties: {
							bookingReference: bookingRef,
							data: responseData
						}
					});

					if (response && response.data && response.data.transactions.length > 0) {
						const transaction = response.data.transactions[0];

						const chosenPaymentOption = JSON.parse(localStorage.getItem("storedSelectedPaymentOption")).paymentAmounts[0];
						const dueDate = new Date(JSON.parse(localStorage.getItem("storedCheckoutResponse")).invoice.balanceDueDate);
						
						let fullCost = 0;
						let alreadyPaid = 0;
						let voucherDiscount = 0;
						let voucherName = null;
						let priceBreakdownCostLines = [];

						for (let costLine of transaction.costLines) {
							if (costLine.type.toLowerCase() === "transfers.booking") {
								fullCost += costLine.amount;
								var bookedTransfers = JSON.parse(sessionStorage.getItem("BookedTransfers"));
								if (bookedTransfers.length > 0) {
									var bookedTransfer = bookedTransfers.find((x) => x.bookingLink === costLine.resource);
									if (bookedTransfer) {
										transferBookings.push(`${costLine.description.replace('Private transfer', t("PRIVATE_TRANSFERS_CARD_TITLE")).replace('people', t('PEOPLE'))} / ${bookedTransfer.airportName}`);
										priceBreakdownCostLines.push({ description: `${costLine.description.replace('Private transfer', t("PRIVATE_TRANSFERS_CARD_TITLE")).replace('people', t('PEOPLE')) } / ${bookedTransfer.airportName}`, amount: costLine.amount });
									}
									else {
										transferBookings.push(costLine.description);
										priceBreakdownCostLines.push({ description: costLine.description, amount: costLine.amount });
									}
								} else {
									priceBreakdownCostLines.push({ description: costLine.description, amount: costLine.amount });
								}						
							}
							else {
								fullCost += costLine.amount;
								if (costLine.key.toLowerCase() === "villa price") {
									priceBreakdownCostLines.push({ description: t("ACCOMMODATION"), amount: costLine.amount });
								} else if (costLine.key.toLowerCase() === "adw margin") {
									priceBreakdownCostLines.push({ description: t("ADW"), amount: costLine.amount });
								} else {
									const translationMapping = extrasTranslationMapping.find((extra) => {
										return costLine.key.toLowerCase() === extra.type.toLowerCase()
									});
									const extraDescription = translationMapping ? t(translationMapping.code) : costLine.description;
									priceBreakdownCostLines.push({ description: extraDescription, amount: costLine.amount });
								}
							}
						}						


						const selectedExtras = JSON.parse(localStorage.getItem("SelectedExtras"));
						if (transferBookings.length > 0 || (selectedExtras && selectedExtras.length > 0)) {
							setTransfersList(transferBookings.join(", ") + ((selectedExtras.length > 0 && transferBookings.length > 0) ? ", " : ""));
							setShowIncludedPart(true);
						}

						if (transaction.payments && transaction.payments.length > 0) {
							for (let payment of transaction.payments) {
								if (payment.method.toLowerCase() === "card")
									alreadyPaid = payment.amount;
								else if (payment.method.toLowerCase() === "voucher") {
									voucherDiscount = Math.abs(payment.amount);
									voucherName = payment.method;
									let voucherApplied = localStorage.getItem("AppliedVoucher");
									try {
										if (voucherApplied !== null) {
											voucherName = JSON.parse(voucherApplied).Description;
										}
									}
									catch (error) {
										appInsights.trackEvent({
											name: "Error occurred while displaying voucher description.",
											properties: {
												bookingReference: bookingRef,
												data: voucherApplied,
												error: error
											}
										})
									}
								}
							}
						}
						const remainingAmount = fullCost - alreadyPaid - voucherDiscount;
						const allowDD = chosenPaymentOption && chosenPaymentOption.currencyCode === "GBP"
							&& remainingAmount > 0 && dueDate > new Date();

						sessionStorage.setItem("directDebit", allowDD);
						setPricingDetails({
							totalCost: fullCost,
							paidByCard: alreadyPaid,
							remainingToPay: remainingAmount,
							voucherAmount: voucherDiscount,
							voucherName: voucherName,
							balanceDueDate: FormatHelper.formatDate(dueDate),
							showDDSignposting: allowDD
						});

						appInsights.trackEvent({
							name: 'New confirmation displayed',
							properties: {
								bookingReference: bookingRef,
								totalPrice: fullCost,
								amountPaidByCard: alreadyPaid,
								remainingToPay: remainingAmount,
								bookingVoucherAmount: voucherDiscount,
								bookingVoucherName: voucherName,
								balanceDueDate: FormatHelper.formatDate(dueDate),
							}
						});

						setCostLines(priceBreakdownCostLines);
					}
				}
				catch (error) {
					let data = JSON.stringify(response.data);
					appInsights.trackEvent({
						name: "Error occurred while displaying cost details.",
						properties: {
							bookingReference: bookingRef,
							data: data,
							error: error
						}
					})
				}
		
			})
			.catch(error => {
				appInsights.trackEvent({
					name: "Error Catch detected while calling GetTransactions",
					properties: {
						errorMessage: error,
						bookingReference: bookingRef,
					}
				})
				console.error(error);
			})
			.finally(() => {
				setIsLoaded(true);
			});
	}

	function updateExtras() {
		const selectedExtras = JSON.parse(localStorage.getItem("SelectedExtras"));
		if (selectedExtras && selectedExtras.length > 0) {
			let extras = [];
			for (let extra of selectedExtras) {
				extras.push(extra.count + " x " + t(extra.translationKey));
			}
			setExtrasList(extras.join(", "));
		}
	}


	function togglePriceBreakdown() {
		setShowPriceBreakdown(!showPriceBreakdown);
	}

	let totalAndVoucherContent;
	let remainingPaymentAndDDContent;

	if (!isLoaded) {
		totalAndVoucherContent = <Skeleton height={28.5} count={2} />;
		remainingPaymentAndDDContent = (
			<div className="bc-card-body card-body">
				<Skeleton height={28.5} />
				<br />
				<Skeleton height={44.5} />
			</div>
		);
	} else {
		totalAndVoucherContent = (
			<>
				<div className="bc-pb-row">
					<div className="bc-total-price">
						<div className="bc-tp-title">{t("TOTAL_PRICE")}</div>
						<div className="bc-tp-view-breakdown" onClick={togglePriceBreakdown}>
							{t("VIEW_BREAKDOWN")}
						</div>
					</div>
					<div className="bc-pb-price">{FormatHelper.formatPrice(pricingDetails.totalCost)}</div>
				</div>
				{
					pricingDetails.paidByCard > 0 &&
					<div className="bc-pb-row">
						<div className="bc-pb-title">{t("PAID_BY_CARD")}</div>
						<div className="bc-pb-price">{"-" + FormatHelper.formatPrice(pricingDetails.paidByCard)}</div>
					</div>
				}
				{
					pricingDetails.voucherAmount > 0 && pricingDetails.voucherName &&
					<div className="bc-pb-row">
						<div className="bc-pb-title">{pricingDetails.voucherName}</div>
						<div className="bc-pb-price">{"-" + FormatHelper.formatPrice(pricingDetails.voucherAmount)}</div>
					</div>
				}
			</>
		);

		if (pricingDetails.remainingToPay > 0) {
			remainingPaymentAndDDContent = (
				<>
					<div className="bc-blue-line"></div>

					<div className="bc-card-body card-body">
						{selectedLanguage == "en" && currencyCode == "GBP" &&
							<div className="w-100" id="dd-signposting-details">
							<b className="pb-2">Would you like to set up a Direct Debit now?</b>
							<p>You can pay your balance by easy monthly Direct Debit instalments. This spreads out the cost, and means you don't need to remember to make your balance payment.</p>
							</div>
						}
						<div className="w-100">
							<div className="bc-pb-row">
								<div className="bc-total-price">
									<div className="bc-tp-title">{t("AMOUNT_DUE")}</div>
								</div>
								<div className="bc-pb-price">{FormatHelper.formatPrice(pricingDetails.remainingToPay)}</div>
							</div>
							{pricingDetails.balanceDueDate > new Date() &&
								<div className="bc-pb-due-date">{t("DUE_BY_DATE")} {pricingDetails.balanceDueDate}</div>
							}
						</div>

						{pricingDetails.showDDSignposting &&
							<div className="bc-dd-signposting w-100">
								<form action={MVPDomain + "/balance-payment"} method="post" className="d-inline-block">
									<input hidden readOnly name="bookingRef" id="bookingRef" type="text" value={bookingRef} />
									<input hidden readOnly name="mobile" id="mobile" type="text" value={phoneNumber} />
								<button className="btn btn-primary bc-set-dd-btn" type="submit" id="vwo_directDebitButton">
										{t("SET_UP_DD")}
									</button>
								</form>
								<img alt="Direct Debit" src={DirectDebitIcon} className="bc-dd-icon" />
							</div>
						}
					</div>
				</>
			);
		}
	}
	return (
		<div className="bc-body-wrap">
			<div className="bc-image-anchor">
				<div className="bc-image-wrap">
					<img alt="" className="bc-villa-image" src={holidayDetails.villaImageUrl} />
				</div>
				<div className="content-container bc-body">
					<div className="bc-card card">
						<div className="bc-card-body card-body">
							{holidayDetails.startDate && holidayDetails.returnDate &&
								<div className="bc-dates">
									<div className="bc-dates-check-in">
										<div className="bc-dates-date">{holidayDetails.startDate}</div>
										<div className="bc-dates-time">{holidayDetails.checkInTime && (t("CHECK_IN") + ": " + holidayDetails.checkInTime)}</div>
									</div>
									<div className="bc-dates-arrow">&#8640;</div>
									<div className="bc-dates-check-out">
										<div className="bc-dates-date">{holidayDetails.returnDate}</div>
										<div className="bc-dates-time">{holidayDetails.checkOutTime && (t("CHECK_OUT") + ": " + holidayDetails.checkOutTime)}</div>
									</div>
								</div>
							}
							
							<div className="bc-booking-info">
								<div className="bc-basic-info">{holidayDetails.duration + " " + t("NIGHTS") + " · " + holidayDetails.guests + " " + t("GUESTS")}</div>
								<div className="bc-villa-name">{holidayDetails.villaName}</div>
								<div className="bc-pb-title">{holidayDetails.centreName}</div>
								{showIncludedPart &&
									<div className="bc-included">{t("INCLUDED_LIST_TITLE") + ": " + transfersList + " " + (extrasList == null ? " " : extrasList)}</div>
								}
							</div>

							<div className="w-100">
								{totalAndVoucherContent}
							</div>

						</div>

						{remainingPaymentAndDDContent}
					</div>
				</div>
			</div>

			{showPriceBreakdown &&
				<ConfirmationPriceBreakdown
					costLines={costLines}
					setShowPriceBreakdown={setShowPriceBreakdown}
					totalCost={pricingDetails.totalCost}
				/>
			}
		</div>
	)
}

export default ConfirmationBody;