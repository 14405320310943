import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { appInsights } from '../../../services/AppInsights';
import React, { useState, useEffect } from 'react';
import useBus, { dispatch } from 'use-bus';
import PrimaryButton from '../Buttons/PrimaryButton';

const NavigationNext = (props) => {
	const [nextPage, setNextPage] = useState("");
	const { t } = useTranslation();
	let history = useHistory();

	useEffect(() => {
		setNextPagePath(window.location.pathname);
	}, []);

	useBus('onNavigation', (event) => {
		setNextPagePath(event.payload);
	});

	function getCurrentPage() {
		let currentPage;
		currentPage = window.location.pathname.replace("/", "");

		if (currentPage == "contactdetails")
			currentPage = "contactDetails";

		return currentPage;
	}

	function setNextPagePath(currentPath) {
		let nextPagePath;
		switch (currentPath) {
			case "/extras":
				nextPagePath = "/contactdetails";
				break;
			case "/contactdetails":
				nextPagePath = "/payment";
				break;
		}

		setNextPage(nextPagePath);
	}

	function getTranslatedButtonText(){
		let buttonText;
		switch (nextPage) {
			case "/contactdetails":
				buttonText = t("EXTRAS_FORWARD_BUTTON");
				break;
			case "/payment":
				buttonText = t("CONTACT_DETAILS_FORWARD_BUTTON");
				break;
			default:
				buttonText = null;
		}

		return buttonText;
	}

	function navigateForward() {
		appInsights.trackEvent({
			name: 'Navigation',
			properties: {
				way: 'Forward',
				fromPage: window.location.pathname,
				toPage: nextPage
			}
		});

		if (window.location.pathname == "/contactdetails") {
			dispatch("onForwardNavigation");
		}
		else {
			markStepAsComplete();
			history.push(nextPage);
			setNextPagePath(window.location.pathname);
		}
		
	}

	function markStepAsComplete() {
		sessionStorage.setItem(getCurrentPage(), true);
	}

	return (
		<div className="next-button-container align-items-center">
			{getTranslatedButtonText() != null ?
				<div className="d-flex justify-content-start">
					
					<PrimaryButton
						onClick={navigateForward}
						buttonText={getTranslatedButtonText()}
					/>
				</div>
				:
				null
			}
		</div>
	);
}

export default NavigationNext;