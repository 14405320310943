import React, { useState, useEffect } from 'react';

const ImageThumbnails = (props) => {
	let { thumbnails, activeImageIndex, changeActiveImageTo } = props;
	const THUMBNAIL_HEIGHT_PX = 100;

	const [translate, setTranslate] = useState(0);
	const [top, setTop] = useState(0);
	const [bottom, setBottom] = useState("auto");
	const [middleSlide, setMiddleSlide] = useState(null);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		}
	}, []);

	useEffect(() => {
		if (isMounted) {
			window.addEventListener("resize", () => {
				setMiddleSlide(getMiddleSlide());
			});
		}
	}, [isMounted]);

	useEffect(() => {
		let tempMiddleSlide = middleSlide;

		if (tempMiddleSlide === null) {
			tempMiddleSlide = getMiddleSlide();
			setMiddleSlide(tempMiddleSlide);
		}

		if (activeImageIndex <= tempMiddleSlide) {
			setTranslate(0);
			setTop(0);
			setBottom("auto");
		} else if (activeImageIndex >= (thumbnails.length - tempMiddleSlide)) {
			setTranslate(0);
			setTop("auto");
			setBottom(0);
		} else {
			setTranslate((activeImageIndex - tempMiddleSlide) * THUMBNAIL_HEIGHT_PX);
			setTop(0);
			setBottom("auto");
		}
	}, [activeImageIndex]);

	function getMiddleSlide() {
		const windowHeight = window.innerHeight;
		const maxSlidesOnScreen = Math.ceil(windowHeight / THUMBNAIL_HEIGHT_PX);

		if (maxSlidesOnScreen > thumbnails.length) {
			// do not allow the transitions if all the slides already show on screen
			return 999;
		}

		let currentValue = 0;
		let slideNumber = 0;
		while ((currentValue + THUMBNAIL_HEIGHT_PX) < windowHeight / 2) {
			currentValue += THUMBNAIL_HEIGHT_PX;
			slideNumber += 1;
		}

		return slideNumber;
	}

	let content = (
		<div className="is-image-thumbnails-wrap">
			<div className="is-image-thumbnails"
				style={{
					transform: `translateY(-${translate}px)`,
					top: top,
					bottom: bottom
				}}
			>
				{
					thumbnails.map(t => {
						return (
							<img
								alt=""
								key={t.orderIndex}
								src={t.url}
								className={"is-thumbnail" + (activeImageIndex === parseInt(t.orderIndex) ? " active" : "")}
								onClick={() => changeActiveImageTo(t.orderIndex)}
							/>
						);
					})
				}
			</div>
		</div>
	);

	return content;
}

export default ImageThumbnails;