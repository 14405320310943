import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import CountryCodesEnglish from '../../jsonData/Translations/countryCodes.en.json';
import CountryCodesDutch from '../../jsonData/Translations/countryCodes.nl.json';
import CountryCodesGerman from '../../jsonData/Translations/countryCodes.de.json';
import CountryCodesSpanish from '../../jsonData/Translations/countryCodes.es.json';
import CountryCodesFrench from '../../jsonData/Translations/countryCodes.fr.json';
import CountryCodesPortuguese from '../../jsonData/Translations/countryCodes.pt.json';
import CountryCodesItalian from '../../jsonData/Translations/countryCodes.it.json';
import CountryCodesCroatian from '../../jsonData/Translations/countryCodes.hr.json';
import CountryCodesDanish from '../../jsonData/Translations/countryCodes.da.json';
import CountryCodesPolish from '../../jsonData/Translations/countryCodes.pl.json';
import CountryCodesLithuanian from '../../jsonData/Translations/countryCodes.lt.json';
import CountryCodesFinnish from '../../jsonData/Translations/countryCodes.fi.json';
import CountryCodesEstonian from '../../jsonData/Translations/countryCodes.et.json';
import CountryCodesNorwegian from '../../jsonData/Translations/countryCodes.nb.json';
import CountryCodesHungarian from '../../jsonData/Translations/countryCodes.hu.json';
import CountryCodesGreek from '../../jsonData/Translations/countryCodes.el.json';
import CountryCodesBulgarian from '../../jsonData/Translations/countryCodes.bg.json';
import CountryCodesCzech from '../../jsonData/Translations/countryCodes.cs.json';
import CountryCodesRomanian from '../../jsonData/Translations/countryCodes.ro.json';
import CountryCodesSwedish from '../../jsonData/Translations/countryCodes.sv.json';
import CountryCodesSerbian from '../../jsonData/Translations/countryCodes.sr.json';
import CountryCodesSlovak from '../../jsonData/Translations/countryCodes.sk.json';
import CountryCodesLatvian from '../../jsonData/Translations/countryCodes.lv.json';
import CountryCodesMaltese from '../../jsonData/Translations/countryCodes.mt.json';
import CountryCodesSlovenian from '../../jsonData/Translations/countryCodes.sl.json';
import { useTranslation } from 'react-i18next';
import cookieHelper from '../../helpers/CookieHelper';

const countriesMapping = {
	"en": CountryCodesEnglish,
	"en-US": CountryCodesEnglish,
	"nl": CountryCodesDutch,
	"de": CountryCodesGerman,
	"es": CountryCodesSpanish,
	"fr": CountryCodesFrench,
	"pt": CountryCodesPortuguese,
	"it": CountryCodesItalian,
	"hr": CountryCodesCroatian,
	"da": CountryCodesDanish,
	"pl": CountryCodesPolish,
	"lt": CountryCodesLithuanian,
	"fi": CountryCodesFinnish,
	"et": CountryCodesEstonian,
	"nb": CountryCodesNorwegian,
	"hu": CountryCodesHungarian,
	"el": CountryCodesGreek,
	"bg": CountryCodesBulgarian,
	"cs": CountryCodesCzech,
	"ro": CountryCodesRomanian,
	"sv": CountryCodesSwedish,
	"sr": CountryCodesSerbian,
	"sk": CountryCodesSlovak,
	"lv": CountryCodesLatvian,
	"mt": CountryCodesMaltese,
	"sl": CountryCodesSlovenian
}
const selectedCountryMapping = {
	"en": "GB",
	"en-US": "US",
	"nl": "NL",
	"es": "ES",
	"de": "DE",
	"fr": "FR",
	"pt": "PT",
	"it": "IT",
	"hr": "HR",
	"da": "DK",
	"pl": "PL",
	"lt": "LT",
	"fi": "FI",
	"et": "EE",
	"nb": "NO",
	"hu": "HU",
	"el": "GR",
	"bg": "BG",
	"cs": "CZ",
	"sv": "SE",
	"ro": "RO",
	"sr": "RS",
	"sk": "SK",
	"lv": "LV",
	"mt": "MT",
	"sl": "SI"
}

const CountryCode = (props) => {
	const selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");
	const selectedCountry = selectedCountryMapping[selectedLanguage];
	const [selected, setSelected] = useState(selectedCountry ? selectedCountry : "GB");
	let countryLabelsData = countriesMapping[selectedLanguage ? selectedLanguage : "en"];

	if (!countryLabelsData)
		countryLabelsData = CountryCodesEnglish;

	const { t } = useTranslation();

	const handleSelectedCode = (code) => {
		setSelected(code);
		if (code) {
			let countryCode = countryLabelsData[code].secondary;
			
			sessionStorage.setItem("countryCode", JSON.stringify({
				key: code,
				code: countryCode
			}));
		}
		props.handleCountryCodeChange();
	}

	useEffect(() => {
		let keyCode = JSON.parse(sessionStorage.getItem("countryCode"));
		let key = selected;

		if (keyCode) {
			key = keyCode.key
			setSelected(key);
		}
		else {
			let countryCode = countryLabelsData[key].secondary;
			sessionStorage.setItem("countryCode", JSON.stringify({
				key: key,
				code: countryCode
			}));
		}
	}, [countryLabelsData, selected]);

	return (
		<ReactFlagsSelect
			selected={selected}
			onSelect={code => handleSelectedCode(code)}
			searchable={true}
			searchPlaceholder={t("SEARCH_COUNTRY_PLACEHOLDER")}
			customLabels={countryLabelsData}
			showSelectedLabel={false}
			className="menu-flags"
			selectButtonClassName="menu-flags-button"
		/>);
}

export default CountryCode;