import axios from 'axios';
import cookieHelper from '../helpers/CookieHelper';

async function SetBookingCookie() {
	return await axios.post("/api/AbandonedBooking/SetCookie", {
		accommodationBookingResource: localStorage.getItem("AccommodationBookingResource"),
		holidayRef: localStorage.getItem("BookingRef"),
		leadLastName: localStorage.getItem("LeadLastName"),
		bookingDate: new Date().toISOString(),
		inventoryResource: localStorage.getItem("InventoryResource"),
		passengers: localStorage.getItem("Passengers"),
		startDate: localStorage.getItem("DepartureDate"),
		duration: localStorage.getItem("Duration"),
		currencyCode: localStorage.getItem("CurrencyCode"),
		selectedLanguage: cookieHelper.getCookie("SelectedLanguageOption"),
		reservationLink: localStorage.getItem("ReservationLink")
	});
}

async function ResetBookingCookie() {
	return await axios.post("/api/AbandonedBooking/SetEmptyCookie");
}

async function DeleteBookingCookie() {
	return await axios.post("/api/AbandonedBooking/DeleteCookie");
}

export { SetBookingCookie, ResetBookingCookie, DeleteBookingCookie };